.container, .main_nl .row-inner {
	@include container-fixed;

	@media (min-width: $screen-sm-min) {
		width: 750px;
	}
	@media (min-width: $screen-md-min) {
		width: 970px;
	}
	@media (min-width: $screen-lg-min) {
		width: 1170px;
	}
}

.container-fluid {
	@include container-fixed;
}

.container-full {
	max-width: 1920px;
	margin: 0 auto;
}

.container-inside {
	margin: 0 15px;
}

.row {
	@include make-row;
}

@include make-grid-columns;

@include make-grid(xs);

@media (min-width: $screen-sm-min) {
	@include make-grid(sm);
}

@media (min-width: $screen-md-min) {
	@include make-grid(md);
}

@media (min-width: $screen-lg-min) {
	@include make-grid(lg);
}
