.owl-carousel {
	display: block !important;
}

.s24 {
	position: absolute;
	right: 0;
	top: 20px;
}

.product-block .s24 {
	width: 27% !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		@include flex();
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	.subcat, ul, ol {
		margin: 0;
		list-style: none;
		@include flex();
		flex-wrap: wrap;
		li, .subcat_one {
			padding: 15px 15px;
			line-height: normal;
			position: relative;
			width: 33.33%;
			a {
				@include flex();
				flex-wrap: wrap;
				justify-content: center;
				color: $bk;
				align-items: center;
				box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
				img {
					width: calc(100%);
					padding: 20px;
				}
				span {
					padding: 0 0 20px 0;
					display: inline-block;
					text-align: center;
					font-weight: 500;
					font-size: 14px;
					width: 100%;
					text-transform: uppercase;
					min-height: 68px;
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
				padding: 10px;
			}
			@media (max-width: 500px) {
				width: calc(100% / 2);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}

	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}

}

.subcat a {
	padding: 0 10px;
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(25% - 20px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.information-contact {
	.lcol, .rcol {
		width: 50%;
	}
	#all_info {
		h5 {
			font-size: 18px;
			font-weight: 600;
		}
	}
	.contacts_map {
		display: flex;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
			h1 {
				text-align: left;
				margin-top: 20px;
			}
			ul {
				padding: 0;
				list-style: none;
			}
			li {
				padding: 20px 0 20px 65px;
				background: top 30px left no-repeat transparent;
				background-size: 40px auto;
				position: relative;
				span {
					font-size: 18px;
					line-height: 20px;
					display: block;
				}
				&:after {
					content: '';
					position: absolute;
					background: $wh;
					height: 1px;
					width: 75%;
					top: 3px;
				}
				&:first-child {
					&:after {
						display: none;
					}
				}
			}
			@for $i from 6 through 10 {
				li:nth-child(#{$i - 5}) {
					background-image: url(/image/i/contact/#{$i}.PNG);
				}
			}
		}
		.rcol {
			height: 700px;
		}
	}
	.proezd {
		display: flex;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
		}
		.rcol {
			background: url(/image/enter.jpg) center center no-repeat;
			background-size: cover;
			height: 430px;
		}
		h2 {
			font-size: 36px;
			text-align: left;
			margin-top: 40px;
			margin-bottom: 80px;
			font-weight: 600;
		}
		.ped, .avto {
			@include size(170px, 170px);
			background: $wh top 30px center no-repeat;
			background-size: 45px;
			text-align: center;
			display: inline-block;
			padding: 100px 10px 0 10px;
			font-size: 12px;
			vertical-align: bottom;
		}
		.ped {
			margin-left: 65px;
			margin-right: 30px;
			background-image: url(/image/i/contact/ped.PNG)
		}
		.avto {
			background-image: url(/image/i/contact/avto.PNG)
		}
	}
	.proezd2 {
		position: relative;
		padding-bottom: 75px;
		&:after {
			content: '';
			position: absolute;
			background: $wh;
			height: 1px;
			width: 85%;
			bottom: 0;
			left: 65px;
		}
		h5 {
			padding: 60px 0 35px 0;
			font-weight: 600;
		}
		p, h5 {
			font-size: 18px;
		}

	}
	form.content-contact {
		h2 {
			font-size: 36px;
			text-align: left;
			margin: 40px 0;
			font-weight: 600;
		}
		.button1 {
			float: right;
			color: $white;
			font-size: 14px;
			position: absolute;
			bottom: 16px;
			right: 0;
			padding: 6px 52px;
		}
	}
	.form-horizontal .form-group {
		padding: 15px;
		margin: 0;
	}
	@media (max-width: 1199px) {
		.proezd {
			.ped {
				margin-left: 30px;
			}
		}
	}
	@media (max-width: 991px) {
		.proezd {
			.lcol div {
				margin: 0;
			}
		}
	}
	@media (max-width: 767px) {
		.contact-info > div {
			flex-wrap: wrap;
			#map {
				height: 400px;
			}
		}
		.lcol, .rcol {
			width: 100%;
		}
		.proezd {
			h2 {
				margin-bottom: 40px;
			}
			.lcol > div {
				width: 100%;
				height: auto;
				min-height: 50px;
				background-position: center left 5px;
				margin-bottom: 10px;
				text-align: left;
			}
			.ped {
				padding: 20px 10px 20px 60px;

			}
			.avto {
				padding: 10px 10px 10px 60px;
			}
		}
		.proezd2 {
			padding-bottom: 40px;
			h5 {
				padding: 30px 0 15px 0;
			}
		}
		form.content-contact .button1 {
			position: static;
		}
	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px 15px 0;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}
}

.product-block {
	.rating {
		display: inline-block;
		position: absolute;
		left: 20px;
		bottom: 15px;
		background: $wh;
		line-height: 1;
		padding: 1px 4px;
		border-radius: 10px;
	}
	.image {
		position: relative;
		padding: 15px;
	}
	.bottom {
		@include flex();
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-end;
		z-index: 99;
		height: 40px;
		margin: 0 20px;
		padding: 0;
		text-align: center;
		position: relative;
		.action {
			width: 64px;
			height: 40px;
			padding: 0;
			svg {
				width: 25px;
				fill: $wh;
				margin: 10px 20px;
			}
			&.btn-default {

				&:hover, &active, &:focus {
					border-color: $tc;
					color: $tc;
				}
				.svgcontainer {
					position: absolute;
					z-index: 3;
					background: $wh;
					width: 64px;
					height: 100%;
					display: inline-block;
					top: 0;
				}
				.captcontainer {
					position: absolute;
					top: 0;
					width: calc(100% - 64px);
				}
				span {
					text-transform: none;
					letter-spacing: -1px;
				}
			}
			&.btn-cart {
				width: 65px;
			}
			&.cart-nostock {
				left: 0;
				width: auto;
				font-size: 12px;
				max-width: 100px;
			}
			&.btn-oneclick {
				left: 0;
				.svgcontainer {
					left: -2px;
				}
				.captcontainer {
					right: 0;
				}
			}
			&.btn-compare {
				right: 0;
				.svgcontainer {
					right: -2px;
				}
				.captcontainer {
					left: 0;
				}
			}
		}
		.btn-oneclick {
			color: $bk;
			background: none;
			border: none;
			font-size: 16px;
			align-self: flex-end;
			text-transform: none;
			font-weight: 300;
			padding: 0;
			svg {
				width: 20px;
				fill: $tc;
				margin: 0;
			}
		}
		.btn-oneclick:hover {
			color: $tc;
			svg {
				fill: $tc;
			}
		}
	}
	img {
		@include fade();
	}
	.quickview {
		display: inline-block;
		position: absolute;
		right: 20px;
		top: 93px;
		border-radius: 3px;
		fill: $bk;
		width: 30px;
		height: 30px;
		background: $lg;
		@include fade();
		svg {
			width: 21px;
			vertical-align: middle;
		}
	}
	.quickview:hover {
		svg {
			fill: $tc;
		}
	}
	.btn-compare.quickview {
		top: 58px;
		padding: 0;
		border: none;
		svg {
			vertical-align: sub;
		}
	}
}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

.product-filter {
	background: $wh;
	& {
		@include flex();
		justify-content: space-between;
		padding: 15px 0;
		.sortlimit {
			flex: 1;
			&, & > div {
				@include flex();
			}
			& > div {
				align-items: center;
				margin-right: 30px;
				& > span {
					margin-right: 5px;
					font-size: 18px;
					font-weight: 500;
				}
				.selectric {
					background: transparent;
					padding-right: 20px;
				}
			}
		}
		.form-control {
			display: inline-block;
			padding: 0 20px;
			margin: 0 5px;
			@include size(auto, 36px);
			text-transform: capitalize;
		}
		.btn-switch {
			display: inline-block;
			background: transparent;
			border: solid 1px #f5f5f5;
			border-radius: 0;
			padding: 3px;
			height: 35px;
			width: 35px;
			svg {
				fill: #9e9e9e;
				stroke: none;
				@include transition (all 200ms ease 0s);
				width: 25px;
				height: 25px;
			}
			&:hover, &.active {
				svg {
					fill: $bk;
				}
			}
		}
		.btn-switch + .btn-switch {
			display: inline-block;
			background: transparent;
			svg {
				width: 23px;
				height: 23px;
				fill: #9e9e9e;
			}
			&:hover, &.active {
				svg {
					fill: $bk;
				}
			}
		}

	}
}

#products {
	.products-block {
		@include flex();
		flex-wrap: wrap;
		align-items: stretch;
	}
	&.product-list {
		.product-block {
			border: solid 1px $lg;
			border-radius: 0;
			margin: 0;
		}
	}
}

.product-list {
	.name {
		a {
			font-size: 16px;
			font-weight: 400;
			margin: 0 20px;
		}
		span {
			font-size: 12px;
			color: #9e9e9e;
			margin-bottom: 5px;
			display: inline-block;
			float: left;
		}
	}
	.description {
		display: block;
		font-size: 16px;
		color: #9e9e9e;
	}
	.product-meta {
		width: 45%;
		padding-right: 30px;
	}
	.rating {
		position: relative;
		top: 20px;
	}
	.listprice {
		position: relative;
		width: 27%;
		display: flex;
		padding-bottom: 35px;
		.flex_block {
			align-self: flex-end;
			.price, .bottom {
				display: inline-block;
				text-align: left;
			}
		}
		span {
			width: 100%;
		}
		.price-old {
			font-size: 14px;
			font-weight: 300;
		}
		.price-new {
			font-size: 24px;
			font-weight: 800;
			line-height: 1;
		}
	}
	.product-block {
		@include flex();
		background: $wh;
		border-radius: 5px;
		margin-bottom: 35px;
		&:last-child {
			border-bottom: 1px solid $bc;
		}
		.quickview {
			padding: 0;
			font-size: 16px;
			right: calc(50% - 95px);
		}
		.top {
			.price {
				display: none;
			}
		}
		.name {
			font-weight: 300;
			font-size: 18px;
			b {
				text-transform: uppercase;
				margin-bottom: 5px;
				display: inline-block;
			}
		}
		.block-img {
			width: 28%;
			padding: 15px;
		}
		.bottom {
			width: 298px;
			margin: 0;
			justify-content: flex-start;
			.action {
				display: inline-block;
				&.btn-cart {
					width: 130px;
				}
			}
			.cart {
				flex: 0;
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
		}
	}
}

.product-list {
	.listprice .price-profit {
		font-size: 12px;
		display: inline-block;
		float: left;
		font-weight: 400;
		border: solid 1px $new;
		border-radius: 3px;
		padding: 4px 5px;
		width: auto;
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			padding: 15px 0;
		}
		.product-block {
			width: calc(100% / 4 - 33px);
		}
	}
	.listprice {
		display: none;
	}
	.product-block {
		display: inline-block;
		width: calc(25% - 35px);
		background: $wh;
		margin: 0;
		border: solid 1px $lg;
		@include rounded-corners(0);
		@include transition(transform 0.2s ease-in-out);
		.description {
			display: none;
		}
		.name {
			font-size: 16px;
			font-weight: 400;
			margin: 0 20px;
			line-height: 24px;
			a {
				display: inline-block;
				font-size: 15px;
				line-height: 17px;
			}
			b {
				font-weight: 800;
			}
			span {
				font-size: 12px;
				color: #9e9e9e;
				display: inline-block;
			}
			.rating {
				text-align: center;
				padding-bottom: 10px;
				font-size: 14px;
			}
		}
		.product-meta {
			margin-bottom: 25px;
		}
		.price {
			text-align: center;
			line-height: 1;
			margin: 5px 20px 15px 20px;
			@include flex();
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-end;
			display: flex;
			width: 100%;
			position: relative;
			&-new {
				font-weight: 800;
				display: inline-block;
				width: 100%;
				text-align: left;
				margin-top: 7px;
				.cur {
					font-weight: 800;
				}
			}
			&-old {
				font-size: 12px;
				display: inline-block;
				float: left;
				font-weight: 300;
				margin-bottom: 5px;
				text-decoration: line-through;
				color: #9e9e9e;
			}
			&-profit {
				font-size: 12px;
				display: inline-block;
				float: left;
				font-weight: 400;
				border: solid 1px $new;
				border-radius: 3px;
				padding: 4px 5px;
				margin-left: 15px;
			}
		}
	}
	.product_list_item {
		width: calc(33%);
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

.rating, .stars {
	.fa {
		color: #ffbb00;
	}
}

.h1_container {
	font-size: 26px;
	background: $tc;
	h1 {
		font-size: 48px;
		font-weight: 800;
		text-transform: uppercase;
		padding: 24px 0;
		color: $wh;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}
.sticker-block {
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: calc(100% - 30px);
	flex-wrap: wrap;
	.sticker {
		padding: 5px;
		border-radius: 4px;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 17px;
		font-weight: 500;
		background: $wh;
		margin-bottom: 5px;
	}
	.red {
		color: $red;
		border: solid 1px $red;
	}
	.green {
		color: $green;
		border: solid 1px $green;
	}
}
.quick-view .sticker-block {
	width: calc(100% - 60px);
}
.sticker-dellivery-off {
	width: 75px;
	height: 75px;
	position: absolute;
	bottom: 35px;
	left: 20px;
	.delivery-1 {
		fill: #e9e9e9;
	}
	.delivery-2 {
		fill: #2bc067;
	}
	.delivery-3 {
		fill: #f44336;
	}
}