.paging {
	margin: 15px;
	.pull-right {
		line-height: 36px;
	}
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 0;

	li:first-child, li:last-child {
		display:none;
	}
	li.active {
			display: inline;
	}
	> li {
		display: inline;
		> a, > span {
			position: relative;
			float: left;
			margin-right: 5px;
			line-height: 30px;
			width: 35px;
			height: 35px;
			text-decoration: none;
			text-align: center;
			color: $bk;
			font-size: 16px;
			border-radius: 5px;
			padding: 2px;
			cursor: pointer;
			border: 1px solid #F5F5F5;
		}
		&:first-child {
			> a, > span {
				margin-left: 0;
			}
		}
	}

	> li > a, > li > span {
		&:hover, &:focus {
			color: $bk;
		}
	}

	> .active > a, a:hover, a:focus, > .active > span {
		&, &:hover, &:focus {
			color: $wh;
			background: $tc;
			border: none;
		}
	}
	>.active > span {
		&, &:hover, &:focus {
			cursor: default;
		}
	}
}