// Mini basket and shopping cart
// ========================================================================
$cart-heading-color : 				$black !default;
$cart-icon-bg:                      $theme-color-secondary !default;
$cart-icon-color:                   $white !default;
$cart-icon-font-size:               $font-size-base + 2 !default;
$cart-product-font-size:			$font-size-base - 2 !default;

$cart-font-size:					$font-size-base - 2 !default;
$cart-font-weight:					700 !default;
$cart-color:                        $theme-color-tertiary !default;
$cart-color-hover:                  $white!default;
$cart-bg-color:                     transparent !default;
$cart-bg-color-hover:               $theme-color !default;
$cart-font-icon-size:               14px !default;

$cart-number-product-font-size:		10px !default;
$cart-number-product-color:			$white!default;
$cart-number-product-bg:			transparent!default;
$cart-number-product-color-hover:	$white!default;
$cart-number-product-bg-hover:		$black!default;
$cart-number-product-width:		    15px !default;
$cart-number-product-height:		15px !default;


$quick-setting-border:              1px solid $border-color !default;
$quick-setting-border-hover:        1px solid $theme-color !default;

// Mini basket and shopping cart
// ========================================================================

// Last - Blog
// ========================================================================
 $blog-date-bg:                     $theme-bg-threedary !default;
 $blog-date-text:                   $black !default;

//Minicart
$minicart-module-bg:                $theme-bg-default !default;
$minicart-module-font:              600 18px "tahoma"!default;
$minicart-module-list-font-family:  $font-family !default;
$minicart-module-list-font-size:    14px!default;


// Search
// ========================================================================
$search-border:						$border-color-base !default;
$search-bg-color:                   $white !default;
$search-padding: 					0 20px !default;
$search-input-color: 			    $theme-color-tertiary !default;
$search-input-border:               none !default;
$search-input-bg:                   transparent !default;
$search-input-padding:              0 !default;
$search-icon-color:                 $theme-color-tertiary !default;
$search-button-border:              none !default;
$search-button-bg:                  $theme-color !default;
$search-button-padding:             0 !default;

// Contact page
// ========================================================================
$contact-bg:                        $body-bg !default;
$contact-padding:                   20px !default;
$contact-border:                    0 !default;
$contact-icon-color:                $white !default;
$contact-icon-bg:                   lighten($gray, 30%) !default;
$contact-icon-font-size:            $font-size-base + 5 !default;
$contact-icon-hover-bg:             $theme-color !default;
$contact-height:                    338px !default;



// Htab module
// ========================================================================
$htab-border-width:					0px 1px 1px 1px !default;
$htab-nav-padding: 					10px 15px !default;
$htab-nav-margin:                   0 !default;
$htab-nav-color: 					$theme-color-secondary !default;
$htab-bg: 							$wild-sand !default;
$htab-bg-selected: 					$theme-color !default;
$htab-nav-selected-color: 			$white !default;
$htab-nav-border-color: 			transparent !default;
$htab-nav-border-width:             0px 0px 0px 0px !default;
$htab-nav-border-style:             solid !default;
$htab-nav-border-color-hover:       $theme-color !default;
$htab-nav-border-color-selected:    $htab-bg-selected $htab-bg-selected $htab-bg-selected $htab-bg-selected !default;
$htab-nav-content-padding:          20px !default;
$htab-nav-font-size:                $font-size-base + 2!default;


// Mega Menu Module
// ========================================================================
$megamenu-bg: 						transparent !default;
$megamenu-outside-bg:				transparent !default;
$megamenu-border-color:             $border-color !default;			    
$megamenu-border-bottom-color:      $black !default;
$megamenu-font-family:              $font-family-base !default;
$megamenu-link-color: 				$black !default;
$megamenu-font-weight: 				500 !default;
$megamenu-link-hover-color:         $link-hover-color !default;
$megamenu-link-bg-hover-color:      $white !default;
$megamenu-color: 				    $black !default;
$megamenu-caret:                    $black !default;

$megamenu-parent-padding: 			16px 22px !default;
$megamenu-parent-text-transform:	uppercase !default;
$megamenu-margin-bottom:			0px !default; 

$megamenu-sub-bg-color: 			$white !default;
$megamenu-sub-radius:               0px !default;
$megamenu-sub-text-color:           $black !default;
$megamenu-sub-link-color: 			#8899a6 !default;
$megamenu-sub-shadow:    			0px 0px 5px 0px rgba(0, 0, 0, 0.1) !default;
$megamenu-sub-padding:              25px !default;
$megamenu-sub-min-width:  			185px !default;
$megamenu-sub-border:               0 !default;
$megamenu-sub-font-size:            13px !default;
$megamenu-arrow-bg:                 darken($megamenu-sub-bg-color, 20%) !default;
$megamenu-product-name-color:       $theme-color-secondary !default;
$megamenu-text-align:               left !default;
$megamenu-heading-letter:           3px !default;
$megamenu-sub-caret-color:  		$megamenu-sub-text-color !default;
$megamenu-name-hover-color:         $theme-color !default;
$megamenu-sub-link-hover-color:     $theme-color !default;
$megamenu-link-font-size:           $font-size-base + 2 !default;
$megamenu-link-font-family:         $font-family-base !default;
$megamenu-link-hover-border:        $white !default;
$megamenu-heading-title:            $font-family-tertiary !default;
$megamenu-heading-color:            $white !default;
$megamenu-line-border:  			1px solid #646464 !default;
$megamenu-widget-title-color:		$black !default;
// Vertical Menu Module
$vertical-menu-font-family:         $font-family-base !default;
$vertical-menu-content-bg:          #fff!default;
$vertical-menu-content-height:      500px !default;
$vertical-menu-content-padding:     0 !default;
$vertical-menu-content-margin:      0 !default;
$vertical-menu-content-border:      $border-color !default;
$vertical-menu-font-size:           $font-size-base!default;
$vertical-menu-color:               #000 !default;
$vertical-menu-font-weight:         700 !default;
$vertical-menu-link-padding: 		10px 0!default;
$vertical-menu-link-hover-color:    $link-hover-color !default;
$vertical-border-color:             $border-color !default;
$vertical-description-font-family:	$font-family-base !default;
$vertical-description-font-size:    11px !default;
$vertical-description-font-style:   italic !default;
$vertical-description-color: 	    #999 !default;
$vertical-description-font-weight:  400 !default;
$vertical-caret-border:             #f5f5f5 !default;
$vertical-sub-menu-padding:         15px !default;
$vertical-sub-menu-width:           215px !default;
$vertical-sub-color:         		#999 !default;
$vertical-sub-menu-link-color:      #999 !default;
$vertical-sub-menu-font-family:		$font-family-base !default;
$vertical-sub-menu-link-font-size:  $font-size-base  !default;
$vertical-sub-menu-link-font:       $font-family-base !default;
$vertical-sub-menu-heading-color:   $black !default;
$vertical-sub-menu-heading-font-size:   12px !default;
$vertical-sub-menu-heading-font-family:   $font-family-base !default;
$vertical-sub-menu-bg:              	$white !default;
$vertical-sub-menu-link-hover-color:          $theme-color !default;
$vertical-sub-shadow:  				0px 0px 5px 0px rgba(0, 0, 0, 0.35) !default;
$vertical-font-size:                $font-size-base !default;
$vertical-line-height:              normal !default;
$vertical-sub-product-link:         $black !default;


$vertical-widget-title-color:         	$black !default;
$vertical-widget-title-font-size:       $font-size-base !default;
$vertical-widget-title-font-family:     $font-menu-family !default;

// OffCanvas Menu 
// ========================================================================
$offcanvas-menu-bg:                 $silver !default;
$offcanvas-menu-padding:            12px 15px !default;
$offcanvas-menu-link-color:         $white !default;
$offcanvas-menu-font-size:          $font-size-base !default;
$offcanvas-menu-transform:          uppercase !default;
$offcanvas-menu-font-family: 		$font-family-secondary !default;
$offcanvas-menu-border:             1px solid lighten($offcanvas-menu-bg, 10%) !default;
$offcanvas-menu-icon-color:         $white !default;
$offcanvas-menu-icon-font-size:     $font-size-base * 2 + 2 !default;
$offcanvas-menu-icon-padding:       8px 15px !default;
$offcanvas-menu-border-caret:       1px solid $white !default;
$offcanvas-menu-caret-color:        $white !default;
$offcanvas-menu-text-color:         $theme-color-secondary !default;
$offcanvas-menu-text-fs:            $font-size-base + 1 !default;
