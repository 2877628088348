$f: 'Gotham';
$f2: 'Gotham';
$fa: 'FontAwesome';

$tc: #f44336 !default;
$bk: #222222 !default;
$wh: #fff !default;

$dg: #424242 !default;
$mg: #a8b4bf !default;

$lg: #f5f5f5 !default;
$lg2: #9e9e9e !default;
$bc: #ccd4dc !default;

$gb: #a8b4bf !default;
$lb: #f0f9fd !default;

$new: #2bc067 !default;
$special: #f44336 !default;

$red: #f44336 !default;
$green: #2bc067 !default;

@mixin fade() {
	@include transition(all 0.2s ease-in-out);
}

@mixin flex() {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}