
@font-face {
	font-family: 'Gotham';
	src: url('/fonts/gotham/GothamPro-Light.eot');
	src: url('/fonts/gotham/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gotham/GothamPro-Light.woff') format('woff'),
		url('/fonts/gotham/GothamPro-Light.ttf') format('truetype'),
		url('/fonts/gotham/GothamPro-Light.svg#GothamPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Gotham';
	src: url('/fontsGotham/gotham/GothamPro-Medium.eot');
	src: url('/fonts/gotham/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gotham/GothamPro-Medium.woff') format('woff'),
		url('/fonts/gotham/GothamPro-Medium.ttf') format('truetype'),
		url('/fonts/gotham/GothamPro-Medium.svg#GothamPro-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Gotham';
	src: url('/fonts/gotham/GothamPro.eot');
	src: url('/fonts/gotham/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gotham/GothamPro.woff') format('woff'),
		url('/fonts/gotham/GothamPro.ttf') format('truetype'),
		url('/fonts/gotham/GothamPro.svg#GothamPro') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}