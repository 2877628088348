.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.product-info .pricerow .price {
	font-family: $product-price-font-family;
	font-weight: bold;
	.price-new {
		color: $black;
		font-size: 24px;
		text-transform: uppercase;
		font-weight: 800;
		width: 100%;
		display: inline-block;
		margin-top: 10px;
	}
	.price-old {
		text-decoration: line-through;
		color: #9e9e9e;
	}
	&.detail {

		.price-old {
			font-size: 26px;
		}
		ul {
			margin: 0;
		}
	}
}

#tab-gifts {
	h3 {
		margin: 0 0 25px 0;
	}
	ul {
		padding: 40px 0 0;
		list-style: none;
		margin: 0 auto;
		li {
			&:after {
				content: '\0418\041B\0418';
				display: block;
				text-align: center;
				font-size: 18px;
				color: $black;
				margin: 15px 0;
			}
			&:last-child:after {
				content: none;
			}
		}
		a {
			display: flex;
			align-items: center;
		}
		div {
			display: inline-block;
			img {
				width: 120px;
			}
			&.gift_name {
				font-size: 22px;
				color: $black;
				margin-left: 25px;
				&:hover {
					color: $theme-color;
				}
			}
		}
	}
	p {
		margin: 20px 0;
		color: $black;
		text-align: center;
	}
	@media(max-width: 520px) {
		ul {
			li:after {
				text-align: center;
			}
			a {
				flex-wrap: wrap;
			}
		}
		div {
			width: 100%;
		}
		.gift_name {
			text-align: center;
		}
		.gift_image {
			text-align: center;
			margin-bottom: 20px;
		}
	}
}

.table-description {
	margin-left: 40px;
	td {
		border: 1px solid #999;
		padding: 2px 5px;
	}
}

.product-label {
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	position: absolute;
	z-index: 30;
	top: 20px;
	line-height: 17px;
	padding: 5px;
	color: $wh;
	border-radius: 4px;
	text-transform: uppercase;
	background: $wh;
	/*&.sale {
		right: 20px;
		color: $special;
		border: solid 1px $special;
	}
	&.new {
		left: 20px;
		color: $new;
		border: solid 1px $new;
	}*/
	&.sale-border {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 40px;
		height: 20px;
		position: absolute;
		top: 15px;
		border: 2px solid #d95e41;
		right: 10px;
		color: #474747;
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.product-info {
	.product-info-bg {
		@include flex();
		align-items: stretch;
		flex-wrap: wrap;
	}
	.box-product-infomation {
		margin-top: 20px;
	}
	.image-container {
		padding: 0 0 30px 0;
	}
	.title-product {
		margin: 0;
		font-weight: 300;
		text-align: center;
		padding: 60px 0 30px 0;
		width: 100%;
		b {
			font-weight: 800;
			text-transform: uppercase;
		}
	}
	.dop_text {
		font-size: 12px;
		line-height: 18px;
		color: $lg2;
		.adress {
			font-size: 14px;
			color: $bk;
			text-transform: uppercase;
		}
		.btn-primary {
			padding: 5px 10px;
			height: auto;
			line-height: 24px;
			margin-bottom: 16px;
		}
		span.storage_time {
			color: $tc;
		}
	}
	.product-view {
		padding: 0 0 30px 15px;
		color: $dg;
		h1, .price-new {
			color: $dg;
		}
		.price-profit {
			font-size: 12px;
			display: inline-block;
			font-weight: 400;
			border: solid 1px $new;
			border-radius: 3px;
			padding: 4px 5px;
			width: auto;
			color: $bk;
			margin-left: 15px;
		}
		.cheaprow {
			margin-top: 20px;
			.btn-oneclick, .credit, .cheap_invoice {
				padding: 0;
				width: 100%;
				text-align: left;
			}
			span {
				text-transform: none;
				font-size: 16px;
				color: $bk;
				font-weight: 400;
			}
		}

		.product_advantage {
			color: $dg;
			svg {
				width: 31px;
				margin-right: 5px;
				margin-top: 15px;

			}
			p {
				display: inline-flex;
				span {
					display: inline-block;
					float: left;
				}
			}
			p.two_lines {
				padding-top: 15px;
				span {
					max-width: 88%;
				}
				span + span {
					margin-top: -15px;
				}
			}
			p.two_lines1 {
				svg {
					margin-top: 2px;
				}
			}
			p.two_lines2 {
				svg {
					margin-top: 4px;
				}
			}
		}
	}
	.row {
		padding: 0 15px;
		.prod_rating {
			float: left;
		}
		.product-row {
			@include flex();
			justify-content: space-between;
			&.attrow {
				margin-bottom: 22px;
			}
			.product-col {
				flex: 0 1 auto;
				&.product-buttons {
					flex: 0 0;
				}
			}
			.btn-container {
				width: 200px;
				padding-bottom: 30px;
				button {
					width: 100%;
				}
			}
		}
		hr {
			border-top: 2px solid $bc;
		}
	}
	.pricerow {
		align-items: flex-end;
		.price {
			font-weight: 300;
			flex: 0 0 100%;
			margin-bottom: 22px;
			&-old {
				font-size: 14px;
				position: relative;
				left: 5px;
				color: $bk;
				opacity: .5;
			}
			&-new {
				color: $bk;
				font-size: 48px;
				font-weight: 500;
				display: block;
				line-height: 1;
			}
		}
	}
	.btn-compare {
		padding: 0;
		height: 40px;
		width: 80px;
		margin-left: 20px;
		&:hover {
			svg {
				fill: $bk;
			}
		}
		svg {
			@include fade();
			width: 28px;
			height: 28px;
			margin: 2px 0;
		}
	}
	.buttonrow {
		align-content: center;
		li {
			&:first-child {
				flex: 0 0 250px;
			}
			.btn {
				width: 200px;
			}
		}
	}
	.cheaprow {
		flex-wrap: wrap;
		& > div {
			margin-bottom: 10px;
			&.cheap_invoice {
				flex: 70%;
			}
		}
		&:hover {
			color: $bk;
		}
		.btn-oneclick {
			svg {
				margin-right: 5px;
				fill: $bk;
				stroke: none;
				width: 24px;
				height: 24px;
			}
			span {
				margin-top: -5px;
				display: inline-block;
				float: left;
			}
		}
		svg {
			margin-right: 5px;
			width: 24px;
			height: 24px;
			float: left;
		}
	}
	.attributes {
		margin-bottom: 30px;
		li {
			font-size: 14px;
			margin-top: 15px;
			line-height: 18px;
			color: $dg;
			text-transform: uppercase;
			font-weight: bold;
			b {
				font-weight: 400;
				text-transform: none;
				font-size: 16px;
				margin-left: 20px;
				span a {
					color: $lg2;
					text-decoration: underline;
					text-underline-position: under;
				}
			}
			&.product-country {
				position: relative;
				span {
					font-weight: inherit !important;
				}
				img {
					bottom: -2px !important;
					margin-left: 10px !important;
				}
			}
		}
		li:first-child {
			margin-top: 0;
		}

	}
	.image-additional {
		margin: 0 50px;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
	}
	.image-additional .item {
		border: 3px solid $wh;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a {
			display: inline-block;
			border: $wh 2px solid;
			&.active {
				border-color: $tc;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;
		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: none;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $wh;
		max-width: 110px;
		@include rounded-corners(0);
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(33px, 40px);
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			cursor: pointer;
			text-align: center;
			border: #4f4f4f 1px solid;
			height: 21px;
			width: 25px;
			&.add-up {
				margin-bottom: -1px;
				svg {
					@include transform(translateZ(1px) rotate(180deg));
				}
			}
			&.add-down {

			}
			&:hover, &:focus {
				color: $tc;
			}
		}
		svg {
			width: 100%;
			fill: $dg;
		}
		.quantity-number {
			border: #4f4f4f 1px solid;
			margin: 0;
			input {
				@include size(75px, 39px);
				text-align: center;
				display: inline-block;
				box-shadow: none;
				padding: 0;
				border: none;
				border-radius: 0;
				font-size: 16px;
				line-height: 1;
				font-style: normal;
				color: #000000;
				margin-right: -1px;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			a.btn-sm {
				padding: 0 19px;
			}
		}
		.cart {
			padding-bottom: 0;
		}
	}
	.nav-tabs {
		text-align: center;
		@include flex();
		& > li {
			display: inline-block;
			& > a {
				padding: 0;
				margin-right: 30px;
				margin-bottom: 40px;
				color: $bk;
				font-size: 24px;
				font-weight: 800;
				position: relative;
				display: inline-block;
				text-transform: uppercase;
				border-bottom: solid 3px #fff;
			}
		}
		li.active a, li a:hover {
			border-color: $tc;
		}
	}
	.tab-content {
		border: none;
		padding: 0;
		#tab-attributes .desc-header-wrapper + .desc-pane.attribs .arow {
			background: none;
		}
		.desc-header-wrapper {
			text-align: center;
			position: relative;
			.desc-header {
				padding: 10px 0;
				color: $bk;
				font-size: 34px;
				font-weight: 900;
				position: relative;
				display: inline-block;
			}
		}
		#tab-description {
			padding: 30px 15px;
			list-style: none !important;
			div[class^='col-'] {
				float: none;
				width: 100%;
			}
			.flash_container {
				text-align: center;
				object {
					height: 55vw;
					max-width: 750px;
					max-height: 550px;
				}
			}
		}
		.attribs {
			.arow {
				color: $dg;
				@include flex();
				font-size: 16px;
				padding: 2px 0;
				&:nth-child(odd) {
					background: $lg;
				}
				div {
					padding: 5px 0;
					font-size: 18px;
				}
				.asingle {
					text-align: left;
					flex: 1;
					padding: 15px 20px;
				}
				.acol {
					flex: 0 0 50%;
					padding-left: 20px;
					&:first-child {
						text-align: left;
					}
					&:last-child {
						font-weight: 500;
						color: #222222;
					}
				}
			}
		}
		#tab-downloads {
			ul {
				margin: 0;
				li {
					@include flex();
					padding: 15px 0;
					font-size: 18px;
					svg {
						margin-top: 0;
						float: left;
						margin-right: 25px;
					}
				}
			}
		}
		#tab-videos {
			.videos {
				padding: 15px 40px;
			}
		}
		#tab-review {
			padding: 30px 0;
			.recaptcha_container label {
				display: none;
			}
			.rating {
				margin-left: 15px;
			}
		}
	}
}

.quick-view {
	.manufacturer_country {
		bottom: -2px !important;
	}
}

.storage_widget {
	padding: 15px 0 0;
	border-top: none !important;
	color: $dg;
	line-height: 16px;
	b {
		font-weight: 700;
	}
	table tr {
		td {
			padding-left: 0 !important;
			&:last-child {
				text-align: center !important;
			}
			&:first-child {
				display: none;
			}
			&:last-child span {
				background: #f92626 !important;
				border-radius: 5px !important;
				text-align: left !important;
				padding: 6px 10px !important;
				color: $wh !important;
				border: 1px #f92626 solid;
				font-family: $f !important;
				font-weight: 500;
				@include fade();
				&:hover {
					background: $wh !important;
					color: #f92626 !important;
				}
			}
		}
	}
	> div {
		font-size: 14px !important;
	}
}

.quick-view {
	#content {
		padding: 0 15px !important;
	}
	#sidebar-main {
		margin-bottom: 0;
	}
	.product-info {
		h1 {
			font-size: 30px;
		}
		.product-view {
			padding: 0 0 30px 0;
		}
		.title-product {
			padding: 15px 0;
			font-size: 30px;
			font-weight: 500;
		}
		.row {
			.list-unstyled {
				justify-content: left;
				width: auto;
				&.attrow li {
					font-size: 17px;
				}
				li {
					align-self: center;
				}
			}
			.pricerow {
				margin: 15px 0;
				.price-old {
					text-decoration: line-through;
					color: #c4c4c4;
					font-size: 12px;
					margin-top: 4px;
				}

			}
			.buttonrow {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				li {
					&:first-child {
						flex: 0 0 auto;
					}
				}
			}
			#button-cart {
				padding: 0;
				width: 170px;
				margin: 0;
			}
			#button-cart-credit {
				margin-left: 23px;
				cursor: pointer;
			}
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.product-info {
	.stock {
		float: right;
		span {
			color: #2bc067;
			font-size: 14px;
		}
		svg {
			fill: #2bc067;
			width: 11px;
			margin-top: 12px;
			float: left;
			margin-right: 5px;
		}

	}
	.credit-container span,
	.compare_button span,
	.custom-color span,
	.custom-color {
		cursor: pointer;
		color: $bk;
		text-underline-position: under;
	}
	.product-view {
		.prod_rating,
		.compare_button,
		.custom-color,
		.credit-container {
			float: left;
			display: inline-block;
			&:hover span, &:hover svg, &:hover {
				color: $tc;
				fill: $tc;
			}
			svg, span {
				float: left;
				display: inline-block;
				@include transition(.0s opacity linear);
			}
		}
		.prod_rating {
			margin-top: 5px;
		}

		.right {
			padding-right: 0;
			padding-left: 0;
		}
		.left {
			padding-right: 30px;
			padding-left: 0;
		}

	}
	.button_top {
		@include flex();
		justify-content: space-between;
		border-bottom: solid 1px $lg;
		padding-bottom: 5px;
		margin-top: 20px;
		margin-bottom: 30px;
		svg {
			fill: $tc;
		}
	}
	.block_price {
		padding: 25px 20px 30px 25px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
		border-radius: 3px;
	}

}

.credit-container,
.compare_button,
.custom-color {
	svg {
		width: 20px;
		fill: #222222;
		margin-right: 5px;
		margin-top: 6px;
	}
}

#image-additional .fa {
	color: #424242;
	font-size: 15px;
}

.product-info .image-additional .item a.active {
	border: none;
}

.product-info .product-view .btn-oneclick {
	& span, & svg, & {
		@include transition(.0s opacity linear);
		cursor: pointer;
	}
	&:hover span, &:hover svg, &:hover {
		color: $tc;
		fill: $tc;
	}
}

.product-info .product-view .cheap_invoice, .product-info .product-view .credit {
	& span, & svg, & {
		@include transition(.0s opacity linear);
		cursor: pointer;
	}
	&:hover span, &:hover svg, &:hover {
		color: $tc;
	}
}

#tab-review .desc-pane {
	padding-bottom: 20px;
}

#tab-review {
	color: $dg;
	padding-bottom: 20px;
}

#tab-review table.none {
	display: none;
	padding-bottom: 0;
}

.col_rev {
	display: inline-block;
	width: 100%;
	.date {
		float: right;
		font-weight: 500;
	}
	strong {
		font-weight: 500;
	}
}

.table-striped > tbody > tr:nth-child(odd) {
	background: none;
}

.on_rew {
	background: white;
	border: solid 1px $lg2;
	color: $bk;
	margin-right: 30px;
}

.on_rew + span {
	width: 270px;
}

.on_rew.none {
	display: none;
}

#form-review-container {
	margin-top: 40px;
	.form-group {
		margin-bottom: 0;
	}
}

.tab_mobile {
	font-weight: 800;
	text-transform: uppercase;
	padding-top: 20px;
	display: inline-block;
}

.tab_mobile svg {
	fill: $bk;
	margin-left: 17px;
}

.kit_block.product-grid {
	padding-bottom: 100px;
	.tab-header {
		font-size: 24px;
		line-height: 32px;
		text-transform: uppercase;
		font-weight: 800;
		color: $bk;
		padding-bottom: 10px;
		margin-bottom: 30px;
		border-bottom: 1px solid $lg;
		.container {
			padding: 0;
		}
	}
	.producs_block {
		@include flex();
		.plus, .total {
			align-self: center;
			width: 4%;
			text-align: center;
			font-size: 28px;
			font-weight: 400;
		}
		.product_list_item {
			.price {
				margin: 0 20px 20px 20px;
				height: auto !important;
			}
			.product-meta a {
				font-weight: 300;
			}

		}
		.name a, .product-meta {
			margin: 0;
		}
		.kit_total {
			border: none;
			align-self: center;
			background: $lg;
			.btn-primary {
				max-width: 170px;
			}
			p {
				font-weight: 500;
				text-align: left;
			}
			.price {
				padding: 30px;
				margin: 0;
				height: auto !important
			}
			.bottom {
				margin-bottom: 40px;
			}
			.price-old {
				margin-bottom: 30px;
				display: inline-block;
				width: 100%;
				text-align: left;
			}
		}

	}

}

.qwpage .row-offcanvas-left .sidebar-offcanvas {
	display: none !important;
}

.qwpage .quick-view #content {
	padding: 30px !important;
}

.fancybox-skin .close_a {
	position: absolute;
	top: 30px;
	right: 30px;
}

.fancybox-skin .close_a svg {
	width: 15px;
	fill: $bk;
	opacity: 1;
}

.qwpage .product-info .compare_button {
	float: right;
}

.qwpage .product-info #image-additional {
	margin: 30px;
}

.qwpage .product-info #button-cart-credit:hover {
	color: #f44336;
}
