.information-payment {
	#content {
		overflow: hidden;
	}

	.well {
		padding: 0 !important;
		color: #303032;
		font-size: 16px;
	}

	#payment {
		p {
			font-size: 18px;
			font-family: $f;
		}
		h2 {
			color: $bk;
			font-size: 24px;
			text-transform: none;
			font-family: $f;
		}
	}

	#content .well > h1 {
		margin-top: 50px;
		margin-bottom: 0;
		padding-bottom: 10px;
		position: relative;
		display: block;
		padding-left: 30px;
	}

	#content .well > h1:after {
		width: 30px;
		height: 2px;
		background: #2989d5;
		content: '';
		position: absolute;
		bottom: 0;
		left: 34px;
	}

	@media (max-width: 991px) {
		#payment .payment-name {
			font-size: 18px;
		}
		.well {
			font-size: 14px;
		}
	}

	@media (max-width: 767px) {
		#payment .payment-name {
			margin-top: 20px;
		}
	}

	#payment .cash_left {
		padding: 0 0 0 30px;
	}

	#payment .cash_right {
		padding: 0;
		margin-top: 20px;
	}

	#payment .cash {
		margin: 0;
		padding: 0;
	}

	@media (max-width: 991px) {
		#payment .cash_left {
			padding: 0 30px;
		}
	}

	#payment .no-cash_left {
		padding: 0 0 40px 30px;
	}

	#payment .no-cash_right {
		padding: 0;
	}

	#payment .no-cash {
		padding: 0;
		border-top: none;
		border-bottom: none;
		margin: 70px 0 0 0;
	}

	#payment .gr {
		display: block !important;
	}

	#payment .payment_grid {
		-webkit-flex-wrap: wrap;
		@include flex();
		height: 100%;
		font-size: 12px;
		overflow: hidden;
	}

	#payment .no-cash_right .payment_grid .gr {
		width: 20%;
		padding: 10px;
		border-left: none;
		position: relative;
		background: #fff;
		top: 0;
	}

	#payment .no-cash_right .payment_grid .gr .gr-inner {
		border: 1px solid $bc;
		padding: 30px;
		text-align: center;
		@include flex();
		flex-wrap: wrap;
		justify-content: center;
		height: 100%;
		align-content: baseline;
	}

	#payment.apple .no-cash_right .payment_grid .gr {
		width: calc(20% - 1px);
	}

	#feature1 {
		z-index: 50;
		left: -20%;
	}

	#feature2 {
		z-index: 40;
		left: -40%;
	}

	#feature3 {
		z-index: 30;
		left: -60%;
	}

	#feature4 {
		z-index: 20;
		left: -80%;
	}

	#feature5 {
		z-index: 20;
		left: -100%;
	}

	#payment .payment_grid .gr:first-child, #payment .payment_grid .gr:last-child {
		border-left: none !important;
	}

	#payment .payment_grid .gr:last-child {
		border-right: none !important;
	}

	#payment .payment_grid .gr img {
		margin: 0 0 10px 0;
		max-width: 65px;
		align-self: center;
	}

	#payment .payment_grid .gr p {
		margin: 0;
		clear: both;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
		width: 100%;
	}

	@media (max-width: 991px) {
		#payment .cash_right img {
			margin-top: 30px;
		}
		#payment .no-cash_left {
			padding: 0 30px 40px 30px;
		}
		#payment .no-cash_right .payment_grid .gr {
			width: 100%;
			display: flex !important;
			align-items: center;
			border-right: none;
			border-left: none;
		}
		#payment.apple .payment_grid .gr {
			width: calc(100% - 1px);
		}
		#payment .payment_grid .gr:nth-child(-n+2) {
			border-bottom: none;
		}
		#payment .payment_grid .gr:nth-child(3), #payment .payment_grid .gr:nth-child(4) {
			border-bottom: none;
		}
		#feature1 {
			left: -100%;
		}
		#feature2 {
			left: -100%;
		}
		#feature3 {
			left: -100%;
		}
		#feature4 {
			left: -100%;
		}
		#feature5 {
			left: -100%;
		}
		#payment .payment_grid .gr p {
			display: inline;
		}
		#payment .payment_grid .gr img {
			flex-shrink: 0;
		}
	}

	@media (max-width: 767px) {
		#payment .payment_grid .gr {
			width: 100%;
		}
		#payment .no-cash_left {
			padding-bottom: 20px;
		}
	}

	@media (max-width: 600px) {
		#payment .no-cash_right .payment_grid .gr {
			width: 100%;
		}
		#feature1, #feature2, #feature3, #feature4, #feature5 {
			left: -100%;
		}
	}

	@media (max-width: 500px) {
		.payment_grid .gr {
			width: 100%;
		}
	}

	#payment .bank-card_left {
		padding: 0 0 0 30px;
	}

	#payment .bank-card_right {
		padding: 0 70px;
	}

	#payment .bank-card {
		padding: 0 0 50px 0;
		border-bottom: 1px solid $bc;
		margin: 20px 0 0 0;
	}

	#payment .bank-card_right__ul li {
		list-style-type: none;
		margin: 0.5em 0;
		padding: 0 0 0 40px;
	}

	#payment .bank-card_right__ul li:nth-child(1) {
		background: url('/image/payment/card-list-1.png') no-repeat 0 0.1em;
		padding-bottom: 10px;
	}

	#payment .bank-card_right__ul li:nth-child(2) {
		background: url('/image/payment/card-list-2.png') no-repeat 0 0.1em;
		padding-bottom: 10px;
	}

	#payment .bank-card_right__ul li:nth-child(3) {
		background: url('/image/payment/card-list-3.png') no-repeat 0 0.1em;
		padding-bottom: 10px;
	}

	#payment .bank-card_right__ul li:nth-child(4) {
		background: url('/image/payment/card-list-4.png') no-repeat 0 0.1em;
		padding-bottom: 5px;
	}

	#payment .bank-card_right__ul {
		padding-left: 0;
		margin-top: 20px;
		margin-bottom: 65px;
		font-size: 14px;
	}

	@media (min-width: 992px) and (max-width: 1199px) {
		#payment .bank-card_right__ul li:nth-child(2) {
			padding-bottom: 0;
		}
		#payment .bank-card_right__ul li:nth-child(3) {
			padding-bottom: 0;
		}
	}

	@media (max-width: 991px) {
		#payment .bank-card_left {
			padding: 0 30px;
		}
	}

	@media (max-width: 767px) {
		#payment .bank-card_right {
			padding-right: 30px;
			padding-left: 30px;
		}
		#payment .bank-card {
			padding-bottom: 20px;
		}
		#payment .bank-card_right__ul {
			margin-bottom: 20px;
			font-size: 13px;
		}
	}

	@media (max-width: 400px) {
		#payment .bank-card_right__ul li:nth-child(1) {
			background: url('/image/payment/card-list-1-1.png') no-repeat 0 0.1em;
		}
		#payment .bank-card_right__ul li:nth-child(2) {
			background: url('/image/payment/card-list-2-2.png') no-repeat 0 0.1em;
		}
		#payment .bank-card_right__ul li:nth-child(3) {
			background: url('/image/payment/card-list-3-3.png') no-repeat 0 0.1em;
		}
	}

	#payment .terminals {
		background: url('/image/payment/terminal-background.png') no-repeat 0 3.2em;
		background-size: 100%;
	}

	#payment .terminals .terminals-1_right .terminals-1_right__image img, #payment .terminals .terminals-2_left .terminals-2_left__image img, #payment .terminals .terminals-3_right .terminals-3_right__image img, #payment .terminals .terminals-5_right .terminals-5_right__image img {
		display: inline-block;
	}

	#payment .terminals .terminals-3_right::before {
		content: '';
		position: absolute;
		right: 8px;
		top: -170px;
		border: 359px solid transparent;
		border-right: 586px solid #2a89d6;
		z-index: 1;
	}

	#payment .terminals .terminals-1_left, #payment .terminals .terminals-3_left, #payment .terminals .terminals-4_right, #payment .terminals .terminals-5_left {
		padding: 0 0 0 30px;
	}

	#payment .terminals .terminals-1_right, #payment .terminals .terminals-2_left, #payment .terminals .terminals-3_right, #payment .terminals .terminals-4_left, #payment .terminals .terminals-5_right {
		padding: 0;
	}

	#payment .terminals .terminals-1_right .terminals-1_right__image {
		text-align: right;
	}

	#payment .terminals .terminals-2 {
		padding-top: 60px;
	}

	#payment .terminals .terminals-2_left .terminals-2_left__image, #payment .terminals .terminals-3_right .terminals-3_right__image, #payment .terminals .terminals-5_right .terminals-5_right__image {
		text-align: center;
	}

	#payment .terminals .terminals-2_right {
		padding: 120px 70px 0 0;
	}

	#payment .terminals .terminals-3_left {
		padding-right: 40px;
		padding-top: 165px;
	}

	#payment .terminals .terminals-4_left {
		padding-left: 35px;
	}

	#payment .terminals .terminals-4_right {
		padding-right: 40px;
		padding-top: 155px;
	}

	#payment .terminals .terminals-5 {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	#payment .terminals .terminals-5_left {
		padding-right: 140px;
		padding-top: 187px;
	}

	#payment .terminals .terminals-2_right__header, #payment .terminals .terminals-3_left__header, #payment .terminals .terminals-4_right__header, #payment .terminals .terminals-5_left__header {
		font-size: 14px;
	}

	#payment .terminals .terminals-2_right__description, #payment .terminals .terminals-3_left__description, #payment .terminals .terminals-4_right__description, #payment .terminals .terminals-5_left__description {
		font-size: 14px;
	}

	#payment .terminals .terminals-1_left__description {
		padding-left: 60px;
		padding-top: 25px;
	}

	#payment .terminals .terminals-2_right__header {
		padding-left: 45px;
	}

	#payment .terminals .terminals-2_right__description {
		padding-left: 45px;
		padding-top: 10px;
	}

	#payment .terminals .terminals-3_left__header {
		padding-left: 65px;
	}

	#payment .terminals .terminals-3_left__description {
		padding-left: 65px;
		padding-top: 10px;
	}

	#payment .terminals .terminals-4_right__header {
		padding-left: 115px;
	}

	#payment .terminals .terminals-4_right__description {
		padding-left: 115px;
		padding-top: 10px;
	}

	#payment .terminals .terminals-5_left__header {
		padding-left: 65px;
	}

	#payment .terminals .terminals-5_left__description {
		padding-left: 65px;
		padding-top: 10px;
	}

	@media (min-width: 992px) and (max-width: 1199px) {
		#payment .terminals .terminals-1_left__description {
			padding-left: 50px;
			padding-top: 10px;
		}
		#payment .terminals .terminals-2_right {
			padding-top: 90px;
			padding-right: 60px;
		}
		#payment .terminals .terminals-3_left {
			padding-top: 135px;
		}
		#payment .terminals .terminals-3_left__header {
			padding-left: 50px;
		}
		#payment .terminals .terminals-3_left__description {
			padding-left: 50px;
		}
		#payment .terminals .terminals-4_right {
			padding-top: 125px;
		}
		#payment .terminals .terminals-4_right__header {
			padding-left: 90px;
		}
		#payment .terminals .terminals-4_right__description {
			padding-left: 90px;
		}
		#payment .terminals .terminals-5_left {
			padding-top: 145px;
			padding-right: 70px;
		}
		#payment .terminals .terminals-5_left__header {
			padding-left: 50px;
		}
		#payment .terminals .terminals-5_left__description {
			padding-left: 50px;
		}
		#payment .terminals .terminals-3_right::before {
			content: '';
			position: absolute;
			right: 8px;
			top: -80px;
			border: 257px solid transparent;
			border-right: 487px solid #2a89d6;
			z-index: 1;
		}
	}

	@media (min-width: 768px) and (max-width: 991px) {
		#payment .terminals .terminals-3_right::before {
			border: none;
			display: none;
		}
		#payment .terminals .terminals-1_left__description {
			padding-left: 30px;
			padding-top: 0;
		}

		#payment .terminals .terminals-2 {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		#payment .terminals .terminals-2_right {
			padding: 90px 25px 0 0;
		}
		#payment .terminals .terminals-2_right__header {
			padding-left: 30px;
		}
		#payment .terminals .terminals-2_right__description {
			padding-left: 30px;
		}

		#payment .terminals .terminals-3_left {
			padding-top: 60px;
			padding-right: 10px;
		}
		#payment .terminals .terminals-3_left__header {
			padding-left: 30px;
		}
		#payment .terminals .terminals-3_left__description {
			padding-left: 30px;
		}

		#payment .terminals .terminals-4_right {
			padding-top: 55px;
		}
		#payment .terminals .terminals-4_right__header {
			padding-left: 60px;
		}
		#payment .terminals .terminals-4_right__description {
			padding-left: 60px;
		}

		#payment .terminals .terminals-5_left {
			padding-top: 70px;
			padding-right: 10px;
		}
		#payment .terminals .terminals-5_left__header {
			padding-left: 30px;
		}
		#payment .terminals .terminals-5_left__description {
			padding-left: 30px;
		}

	}

	@media (max-width: 767px) {
		#payment .terminals {
			background: none;
		}
		#payment .terminals .terminals-3_right::before {
			border: none;
			display: none;
		}
		#payment .terminals .terminals-1_left, #payment .terminals .terminals-3_left, #payment .terminals .terminals-4_right, #payment .terminals .terminals-5_left, #payment .terminals .terminals-3_right {
			padding: 0 30px;
		}
		#payment .terminals .terminals-1_left__description {
			padding-left: 0;
			padding-top: 0;
		}
		#payment .terminals .terminals-2 {
			padding-top: 30px;
		}
		#payment .terminals .terminals-2_right {
			padding: 30px 30px 10px 30px;
		}
		#payment .terminals .terminals-2_right__header, #payment .terminals .terminals-2_right__description, #payment .terminals .terminals-3_left__header, #payment .terminals .terminals-3_left__description, #payment .terminals .terminals-4_right__header, #payment .terminals .terminals-4_right__description, #payment .terminals .terminals-5_left__header, #payment .terminals .terminals-5_left__description {
			padding-left: 0;
		}
		#payment .terminals .terminals-3_right {
			padding-top: 10px;
			padding-bottom: 25px;
		}
		#payment .terminals .terminals-4_left {
			padding: 10px 30px;
		}
		#payment .terminals .terminals-5 {
			padding-top: 20px;
		}
	}

	/* Terminals */

	/* Yandex money */
	#payment .yandex-money_left {
		padding: 0 0 0 30px;
	}

	#payment .yandex-money_left .yandex-money_left__lastRow__boldText {
		font-size: 15px;
	}

	#payment .yandex-money_right {
		padding: 0;
	}

	#payment .yandex-money {
		margin: 0;
		padding: 0 0 50px 0;
		border-top: 1px solid $bc;
		border-bottom: 1px solid $bc;
	}

	#payment .yandex-money .yandex-money_right__blueBackground {
		padding: 34px 85px 35px 90px;
		color: #ffffff;
		background-color: #2a89d6;
		font-size: 16px;
		line-height: 1.5em;
		margin-top: 17%;
	}

	#payment .yandex-money .yandex-money_right__logo {
		text-align: center;
		padding-top: 90px;
	}

	#payment .yandex-money .yandex-money_left__mockup {
		text-align: right;
		padding-top: 30px;
	}

	#payment .yandex-money .yandex-money_right__logo img, #payment .yandex-money .yandex-money_left__mockup img {
		display: inline-block;
	}

	#payment .yandex-money .yandex-money_left__lastRow {
		padding-right: 35px;
		padding-top: 20px;
	}

	#payment .yandex-money .yandex-money_left__lastRow__grayText {
		font-size: 14px;
		color: #b4b4b4;
	}

	#payment .yandex-money .yandex-money_right__screen {
		padding-right: 10px;
	}

	#payment .yandex-money .yandex-money_right__warning {
		margin-left: 0;
		margin-right: 0;
		@include flex();
		align-items: center;
		padding-left: 12%;
		padding-top: 30px;
	}

	#payment .yandex-money .yandex-money_right__warning__icon {
		border-radius: 50px;
		background-color: #2a89d6;
		padding: 10px 19px;
		color: #ffffff;
		margin-right: 30px;
	}

	@media (min-width: 992px) and (max-width: 1199px) {
		#payment .yandex-money .yandex-money_right__blueBackground {
			padding: 23px 35px 29px 30px;
		}
		#payment .yandex-money .yandex-money_right__warning {
			padding-left: 0;
		}
		#payment .yandex-money .yandex-money_left__lastRow {
			padding-right: 5px;
		}
	}

	@media (max-width: 991px) {
		#payment .yandex-money_left {
			padding: 0 30px;
		}
		#payment .yandex-money .yandex-money_right__logo {
			padding-top: 10px;
		}
		#payment .yandex-money .yandex-money_left__mockup {
			text-align: center;
		}
		#payment .yandex-money .yandex-money_right__blueBackground {
			padding: 15px 30px 15px 25px;
			margin-top: 0;
		}
		#payment .yandex-money .yandex-money_right__warning {
			padding-left: 17%;
			padding-top: 10px;
			padding-right: 10px;
		}
		#payment .yandex-money .yandex-money_left__lastRow {
			padding-top: 0;
			padding-bottom: 20px;
		}
		#payment .yandex-money_left .yandex-money_left__lastRow__boldText {
			font-size: 13px;
		}
		#payment .yandex-money .yandex-money_right__warning__icon {
			padding: 10px 18px;
			color: #ffffff;
		}
	}

	@media (max-width: 767px) {
		#payment .yandex-money .yandex-money_right__blueBackground {
			font-size: 14px;
		}
		#payment .yandex-money .yandex-money_right__warning {
			padding-left: 15px;
		}
		#payment .yandex-money .yandex-money_right__warning__icon {
			margin-right: 0;
		}
		#payment .yandex-money .yandex-money_right__warning .pull-left p {
			padding-left: 35px;
			font-size: 12px !important;
		}
		#payment .yandex-money .yandex-money_right__screen {
			padding-right: 0;
		}
	}

	/* Yandex money */

	/* WebMoney */

	#payment .webmoney_left {
		padding: 0 0 0 30px;
	}

	#payment .webmoney_right {
		padding: 50px 70px 0 70px;
	}

	#payment .webmoney {
		margin: 0;
		padding: 0 0 50px 0;
		border-bottom: 1px solid $bc;
	}

	#payment .webmoney_left__ul li {
		list-style-type: none;
		margin: 0.5em 0;
		padding: 0 0 0 40px;
	}

	#payment .webmoney_left__ul li:nth-child(1) {
		background: url('/image/payment/webmoney-list-1.png') no-repeat 0 0.1em;
		padding-bottom: 10px;
	}

	#payment .webmoney_left__ul li:nth-child(2) {
		background: url('/image/payment/webmoney-list-2.png') no-repeat 0 0.1em;
		padding-bottom: 15px;
	}

	#payment .webmoney_left__ul li:nth-child(3) {
		background: url('/image/payment/webmoney-list-3.png') no-repeat 0 0.1em;
		padding-bottom: 10px;
	}

	#payment .webmoney_left__ul li:nth-child(4) {
		background: url('/image/payment/webmoney-list-4.png') no-repeat 0 0.1em;
		padding-bottom: 5px;
	}

	#payment .webmoney_left__ul {
		padding-left: 50px;
		margin-top: 110px;
		margin-bottom: 65px;
		font-size: 14px;
	}

	@media (max-width: 991px) {
		#payment .webmoney_left {
			padding: 0 30px;
		}
		#payment .webmoney_left__ul {
			margin-top: 0;
			margin-bottom: 10px;
		}
		#payment .webmoney_right {
			padding-top: 10px;
		}
	}

	@media (max-width: 767px) {
		#payment .webmoney_right {
			padding-right: 30px;
			padding-left: 30px;
		}
		#payment .webmoney_left__ul {
			padding-left: 0;
			font-size: 12px;
		}
		#payment .webmoney {
			padding-bottom: 10px;
		}
	}

	@media (max-width: 369px) {
		#payment .webmoney_left__ul li:nth-child(1) {
			background: url('/image/payment/webmoney-list-1-1.png') no-repeat 0 0.1em;
			padding-bottom: 0;
		}
		#payment .webmoney_left__ul li:nth-child(2) {
			background: url('/image/payment/webmoney-list-2-2.png') no-repeat 0 0.1em;
		}
		#payment .webmoney_left__ul li:nth-child(3) {
			background: url('/image/payment/webmoney-list-3-3.png') no-repeat 0 0.1em;
		}
	}

	#payment .credit .credit_center {
		padding: 0 30px;
	}

	@media (max-width: 767px) {
		#payment .credit .credit_center div img {
			width: 100% !important;
		}
	}
}

