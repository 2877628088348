body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

.form-control::-moz-placeholder {
	color: $link-color;
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

.owl-related {
	padding: 0 0 15px 0 !important;
	.owl-stage-outer {
		padding: 0 !important;
	}
}

.related_noscroll {
	margin-right: -35px;
}

.desc-header-related {
	& > .container {
		position: relative;
	}
	.scroll-button {
		position: absolute;
		right: 0;
		top: 18px;
	}
	@media (max-width: 767px) {
		display: block;
		.scroll-button {
			position: relative;
			margin-bottom: 15px;
		}
	}
}

.main_nl {
	background: $lg;
}

.newsletter {
	background: $tc;
	color: $wh;
	position: relative;
	padding: 80px 0px 0px 0px;
	overflow: visible;
	.nl_header {
		color: $wh;
		font-size: 30px;
		font-weight: 800;
		position: relative;
		display: inline-block;
		line-height: 1;
		margin-bottom: 20px;
	}
	p {
		font-weight: 300;
	}
	.fl_img {
		position: absolute;
		top: -115px;
		left: -220px;
	}
	.nl_email {
		width: 100%;
		input {
			width: 410px;
			background: $wh;
			color: $bk;
			border: none;
			border-radius: 3px;
			padding: 9.5px 5px;
			float: left;
			@include placeholder(transparentize($wh, .5));
		}
		input::-webkit-input-placeholder { /* WebKit browsers */
			color: #9e9e9e;
		}
		input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: #9e9e9e;
		}
		input::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: #9e9e9e;
		}
		input:-ms-input-placeholder { /* Internet Explorer 10+ */
			color: #9e9e9e;
		}
	}
	.checkbox_custom {
		margin-top: 20px;
		.input-control {
			color: $wh;
			a {
				&:hover {
					color: $wh;
				}
			}
		}
	}
	#nl_submit {
		width: 55px;
		height: 45px;
		background: $wh;
		fill: $tc;
		margin-left: 10px;
		border-radius: 3px;
		float: left;
		padding: 0 18px;
		svg {
			vertical-align: middle;
		}
	}
	#nl_success {
		position: relative;
		padding: 30px 0;
	}
}

.main_articles {
	.row {
		margin: 0;
	}
	background: $wh;
	.one_news {
		padding: 30px;
		h6 {
			text-transform: uppercase;
			font-size: 14px;
			margin: 0;
		}
		.description {
			margin: 25px 0;
		}
		.btn-more-link {
			text-decoration: underline;
			font-size: 16px;
			text-transform: none;
		}
	}
	.one_news {
		border-top: solid 1px $lg;
	}

}

.review_block_home {

	.widget-heading {
		color: #4f4f4f;
		font-size: 24px;
		text-transform: uppercase;
		font-weight: 800;
		margin-bottom: 40px;
	}
	.one_review {

		.left {
			display: inline-block;
			float: left;
			width: 54%;
			padding: 30px 0 50px 70px;
		}
		.right {
			display: inline-block;
			float: right;
			width: 44%;
			padding: 30px 0 65px 0;
			border-left: solid 1px $lg;
			.text {
				color: #9e9e9e;
				font-size: 12px;
			}
		}
		.author_date {
			font-size: 12px;
			color: #9e9e9e;
			.date {
				margin-left: 20px;
			}
		}
		.text {
			margin-top: 10px;
		}
		.img img {
			max-width: 100%;
		}
		.button a {
			font-size: 16px;
			font-weight: 300;
		}
		.price {
			font-size: 24px;
			font-weight: 800;
			margin: 30px 0;
		}

	}
}

.review_carousel {
	.owl-stage-outer {
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
		border-radius: 5px;
		margin: 0 0 100px 0;
	}
	.owl-prev, .owl-next {
		width: 30px;
		height: 30px;
		text-align: center;
		font-size: 15px;
		font-weight: 600;
		padding: 0;
		border-radius: 50px;
		background: $wh;
		box-shadow: 0 4px 5px rgba(0, 0, 0, 0.24), 0 0 5px rgba(0, 0, 0, 0.12);
		& > i {
			margin-top: 7px;
		}
	}
	.owl-prev {
		left: 20px;
	}
	.owl-next {
		right: 20px;
	}
}
