html {
	overflow-x: hidden;
	&.qwpage {
		overflow: hidden;
	}
}

body {
	&[class*="-home"] {
		.header-1 {
			.pav-verticalmenu {
				display: none !important;
				border: 1px solid #000;
			}
			.dropdown {
				&:hover {
					.pav-verticalmenu {
						display: block;
					}
				}
			}
		}
	}
	&[class*="page-"] #content {
		padding: 20px 15px;
	}
	&[class*="information-"] img {
		max-width: 100%;
	}
	&[class*="page-category"] {
		#content {
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="page-product"] #content {
		overflow: visible;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
	}
}

h1 {
	font-weight: 500;
	text-align: center;
	margin: 0;
}

.page-category, .page-special, .page-search, .page-allproduct {
	#page {
		background: $wh;
	}
}

#page {
	min-height: 100vh;
	& > .container {
		min-height: calc(100vh - 560px);
	}
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

#sidebar-main {
	padding: 0;
}

.common-home {
	#sidebar-main {
		background: transparent;
	}
	#content {
		padding: 0 !important;
	}
}

.fancybox-skin {
	background: $wh;
}

header {
	background: $wh;
}

.megamenu {
	text-transform: none;
}

.megamenu {
	li {
		& > a {
			padding-right: 4px;
			padding-left: 4px;
		}
	}
	a {
		color: $wh;
	}
	.text-red {
		a {
			color: $red;
		}
	}
}

a:hover, a:focus {
	color: $tc;
}

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.header-top {
	height: 40px;
	background-color: #4f4f4f;
	color: $wh;
	font-size: 14px;
	font-weight: 500;
	.btn-group {
		margin-right: 20px;
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
		&:hover {

		}
	}
	.topcontacts {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		align-self: center;
		-webkit-align-items: center;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		position: relative;
		svg {
			fill: $tc;
			stroke-width: 1.5px;
			width: 25px;
			margin-right: 10px;
		}
		.media-body {
			width: auto;
			height: 40px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;

			align-items: center;
			-webkit-box-align: center;
			align-self: center;
			justify-content: center;
			-webkit-justify-content: center;
			-webkit-align-items: center;

			color: $wh;
			a {
				color: $gb !important;
			}
		}
		.worktime span {
			margin-right: 10px;
		}
		span {
			font-weight: 300;
		}
	}
}

.bigcat .col-lg-6 {
	padding: 0 !important;
}

.header-bottom {
	position: relative;
	& > .container {
		display: flex;
		position: relative;
	}
	.search_block {
		margin-right: 25px;
	}
	.logo-container {
		flex: 1 1 auto;
		text-align: center;
	}
	.main-menu {
		flex: 1 0 auto;
		align-self: center;
	}
	#searchblock {
		visibility: hidden;
		position: absolute;
		width: 400px;
		height: 85px;
		@include transition(transform .2s ease-in-out);
		transform: scale(0, 1);
		transform-origin: right;
		z-index: 999;
		right: 14px;
		left: auto;
		top: -29px;
		background: $wh;
		form {
			height: 100%;
		}
		&.active {
			visibility: visible;
			transform: scale(1, 1);
		}
	}
	#searchtop, #searchtop_mobile {
		display: inline-block;
		@include rounded-corners(20px);
		background: $wh;
		width: 100%;
		input.form-control {
			background: $lg;
			color: $bk;
			font-style: normal;
			width: 355px;
			border: none;
			@include placeholder($mg);
		}
		.btn-search {
			background: $lg;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			text-transform: none;
			font-size: 16px;
			line-height: 1;
			padding: 0 5px;
			box-shadow: none;
		}
		svg {
			width: 23px;
		}
		svg:hover {
			fill: $tc;
		}
	}
	.header-panel {
		@include flex();
		justify-content: right;
		align-items: center;
		position: absolute;
		right: 0;
		height: 42px;
		top: calc(50% - 18px);
		svg {
			width: 28px;
			height: 28px;
			fill: $tc;
		}
		.phone {
			width: 160px;
			font-size: 14px;
			margin-top: -6px;
			display: flex;
			span {
				span {
					font-size: 15px;
					font-weight: 600;
					line-height: 18px;
					white-space: nowrap;
				}
			}
			svg {
				fill: $tc;
				float: left;
				height: 38px;
				margin: 3px 4px 0 0;
				flex: 0 0 28px;
			}
		}
	}
	.headercart {
		width: 76px;
		margin: 0;
		display: inline-block;
		text-align: center;
		position: relative;
		margin-left: 0 !important;
		.cart-count {
			font-size: 12px;
			font-weight: inherit;
			color: #fff;
			position: absolute !important;
			top: -1px;
			right: 17px;
			padding: 2px;
			min-width: 15px;
			line-height: 11px;
			z-index: 99;
			background: #444;
		}
		#cart {

			&.open .dropdown-toggle {
				box-shadow: none;
			}
			a {
				display: inline-block;
				position: relative;
				width: 100%;
			}
			.icon-cart {
				background: transparent;
				@include size(55px, 47px);
				display: inline-block;
				margin-right: 10px;
				position: relative;
				text-align: center;
				i {
					font-size: 30px;
					padding: 8px;
				}
			}
			.wrap-cart {
				display: inline-block;
				color: $black;
				.cart-total {
					width: 100%;
					text-align: center;
				}
			}
		}
	}
	.headersearch {
		cursor: pointer;
		svg {
			width: 28px;
			position: relative;
			top: -2px;
			path {
				stroke: $bk;
				fill: none;
				stroke-width: 2.5px;
			}
		}
	}
	.product-compare-header {
		border-right: solid 1px $lg;
		border-left: solid 1px $lg;
		padding: 0 35px;
		text-align: center;
		height: 72px;
		margin: 0 20px !important;
		p {
			margin: 0;
			&#compare-total {
				font-weight: bold;
				line-height: 14px;
				font-size: 12px;
			}
		}
		i {
			font-size: 28px;
		}
		a {
			margin-top: 12px;
			display: inline-block;
		}
		svg {
			fill: $tc;
			width: 25px;
			display: block;
		}
	}
	.badge {
		color: $bk;
		background: none;
		font-size: 14px;
		font-weight: inherit;
	}
	.product-compare-header, .headercart, .headersearch {
		margin: 0 18px;
	}
	.svg_header {
		height: 20px;
		display: block;
	}
}

.header-menu {
	background: $lg;
	& > .container {
		position: relative;
	}
}

#partnerpricemodule, #certificat-banner {
	display: inline-block;
	width: 100%;
	padding: 50px 0;
	color: $wh;
	background: url(/image/theme_img/ak-rus/warranty_bck_2.jpg) no-repeat left top;
	background-size: cover;
	margin: 35px 0;
	.img {
		position: relative;
		background: url(/image/theme_img/ak-rus/warranty-3.png) no-repeat center top;
		background-size: contain;
		height: 400px;
		margin: -105px 0;
		z-index: 1;
	}
	h3 {
		color: $wh;
		font-size: 30px;
		font-weight: 900;
	}
	p, a {
		margin-bottom: 15px;
		font-weight: 300;
		font-size: 16px;
	}
	a {
		color: $wh;
		text-decoration: underline;
	}
	a:hover, a:focus {
		color: $tc;
	}
	button {
		padding: 0 50px;
		color: $wh;
		background: $new;
		font-weight: 500;
		font-size: 18px;
		border: 2px solid $wh;
		box-shadow: 0 20px 46px rgba(0, 0, 0, .3);
		&:hover {
			background: $wh;
			color: $new;
			box-shadow: 0 20px 46px rgba(0, 0, 0, .05);
		}
	}
}

#certificat-banner {
	.text {
		a {
			&:hover {
				color: initial;
			}
		}
	}
}

.search_ctrl {
	padding: 0 15px;
}

.compare_rm_all_top {
	position: absolute;
	right: 15px;
	top: 15px;
}

.compare_rm_all_bottom {
	margin: -15px 15px 15px -15px;
	text-align: right;
	display: inline-block;
	width: 100%;
	@media (max-width: 767px) {
		margin-top: 15px;
	}
}

.shopping-cart-table {
	background: $wh;
	font-size: 16px;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			a:hover {
				color: $special;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				color: $special;
			}
		}

	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid #ddd;
	}
	&.table {
		tr td {
			border: 1px #ddd solid;
			&.column-price, &.column-total {
				white-space: nowrap;
			}
		}
	}
	.column-quantity {
		min-width: 75px;
		.btn-block {
			max-width: 100px;
			margin: 0 auto;
			text-align: center;
			span {
				display: inline-block;
			}
		}
		.btn-block {
			min-width: 165px;
			.plus, .minus, input, span {
				display: inline-block;
				float: left;
			}
			.plus, .minus {
				width: 25px;
				height: 26px;
				background: $lg;
				svg {
					width: 9px
				}
			}
			span {
				margin-left: 10px;
				svg {
					width: 24px;
					margin-top: -2px;
				}
			}
			.plus {
				border-radius: 0 5px 5px 0;
			}
			.minus {
				border-radius: 5px 0 0 5px;
			}
		}
		input {
			color: $bk;
			font-size: 16px;
			text-align: center;
			width: 80px;
			height: 26px;
			padding: 0;
			border-color: $lg;
		}
		.btn {
			height: 28px;
			padding: 0;
			width: 40px;
			&.btn-primary {
				svg {
					fill: $wh;
				}
			}
			.btn-default {
				svg {
					fill: $tc;
				}
			}
		}
		svg {
			width: 30px;
			height: 28px;
			cursor: pointer;
			@include fade();
		}
	}

}

table.total-item {
	font-size: 18px;
	float: right;
	margin-top: 10px;
	background: white;
	tr td:last-child {
		width: 125px;
	}
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	@include flex();
	align-items: center;
	margin: 17.5px 0;
	a {
		display: inline-block;
		margin: 0 auto;
		text-align: center;
		&:hover {
			color: $bk;
		}
	}
	img {
		max-height: 55px;
		float: left;
		display: inline-block;
	}
	span {
		font-size: 14px;
		text-align: left;
		height: 23px;
		float: left;
		display: inline-block;
		max-width: 222px;
		margin-left: 10px;
		margin-top: -4px;
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 30px;
			&.ft-mail {
				a {
					text-transform: lowercase;
				}
			}
		}
	}
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: #4f4f4f;
	padding: 45px 0;
	color: $wh;
	font-size: 14px;
	a {
		color: $wh;
		font-weight: 300;
	}
	a:hover, a:focus {
		color: $tc;
	}
	.box {
		.box-heading {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 400;
			margin-bottom: 5px;
		}
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-size: 12px;
		span {
			white-space: nowrap;
		}
	}
	.main-footer {
		display: flex;
		justify-content: space-between;
		margin-left: 100px;
		.box {
			margin-right: 30px;
			font-weight: 300;
		}
	}
	.boss a {
		text-decoration: underline;
		text-transform: none !important;
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		@include element-absolute($white);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $bk;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		width: 100%;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		color: $wh;
		font-size: 16px;
		font-weight: 300;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		align-items: center;
	}
	.payment-img {
		img {
			max-height: 50px;
		}
	}
}

td.column-price span.old, td.column-total span.old {
	font-size: 10px;
	text-decoration: line-through;
	display: block;
}

#buy_form_container .total {
	float: right;
	width: 270px;
	display: inline-block;
	padding: 20px;
	background: $wh;
	border: 1px solid $lg;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
	.total-item strong {
		font-weight: 500;
		float: left;
		margin-bottom: 15px;
		max-width: 453px;
	}
	.table.total-item {
		font-size: 16px;
		margin-top: 0;
		display: inline-block;
		float: none;
		border: none;
		margin-bottom: 15px;
		tbody {
			display: inline-block;
			width: 100%;
		}
		.text-right, .text-left {
			width: 50%;
			display: inline-block;
			float: left;
			border: none;
			padding: 3px 0;
		}
		strong {
			font-weight: 500;
		}
		.total-item {
			width: 100%;
			display: inline-block;
			td:first-child {
				text-align: left;
			}
		}
		.total-item:last-child {
			border-top: solid 2px #f44336;
			margin-top: 10px;
			font-weight: 500;
		}
		.caunt {
			color: $lg2;
			font-size: 12px;
		}
		.value_total {
			border-top: solid 2px $tc;
			margin-top: 10px;
			font-weight: 500;
		}
		p {
			border-bottom: solid 1px;
			display: inline-block;
			line-height: 18px;
		}
	}
}

.shopping-cart-table {
	max-width: 800px;
	float: left;
	display: inline-block;
	border-bottom: 1px solid $lg;
	padding-bottom: 20px;

	& > thead:first-child {
		& > tr:first-child {
			& > td {
				border: none;
				border-bottom: 1px solid $lg;
			}
		}
	}
	&.table {
		thead {
			td {
				font-weight: 500;
				padding-bottom: 8px;
				padding-top: 0;
			}
		}
		tbody {
			.column-price {
				color: $lg2;
			}
			.column-total, .column-price {
				font-weight: 600;
			}
			.column-name {
				font-size: 12px;
				width: 285px;
			}
			.column-image {
				width: 100px;
				padding-right: 30px;
			}
		}
		tr {
			td {
				border: none;
			}
		}
		td {
			padding: 30px 30px 0 0;
		}
	}
}

.item-deleted {
	.attention {
		color: $red;
	}
	.btn-restore {
		display: flex;
		flex-wrap: nowrap;
		margin-top: 16px;
		font-weight: 600;
		line-height: 20px;
	}
	svg {
		@include size(20px,20px);
		margin-right: 8px;
	}
}

#checkout-form .has-feedback .form-control-feedback {
	top: 26px;
	right: 10px
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 20px
}

#checkout-form #payment-form {
	display: none
}

.cart_h {
	margin-bottom: 30px;
}

.buy_products {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
}

.total_wrapper {
	width: 270px;
	height: 0;
	z-index: 5;
}

#checkout-form {
	padding-bottom: 50px;
	display: inline-block;
	.col-sm-12, .col-sm-8 {
		padding: 0;
	}
	h2 {
		font-weight: 800;
		text-transform: uppercase;
		margin: 40px 0 25px 0;

	}
	.buy_comment {
		strong {
			font-weight: 800;
			text-transform: uppercase;
			font-size: 24px;
		}
		.form-control {
			border-color: $lg;
			border-radius: 5px;
			min-width: 570px;
		}
	}
	.form-control {
		border: 1px solid #f5f5f5;
		border-radius: 5px;
		color: $lg2;
		font-size: 12px;
		padding-left: 5px;
		max-width: 370px;
	}
	label {
		font-weight: 400;
		color: $bk;
		margin-left: 5px;
		padding-left: 0;
	}
	.required .control-label:before {
		display: none;
	}
	#shipping_methods {
		.tk-comment {
			display: inline-block;
			.time {
				margin: 0;
			}
			p {
				margin: 20px 0;
				max-width: 570px;
			}
		}
		strong {
			font-weight: 500;
			margin: 25px 0 15px 0;
			display: inline-block;
		}
		.radio_custom {
			display: inline-block;
			float: left;
			margin-right: 10px;

		}

	}
	#payment_methods {
		.payment_methodcod {
			margin-bottom: 17px;
		}
	}
	.buttons {
		padding: 33px 0 0 0;

		label {
			margin: 0 0 15px 0;
		}
	}
	#button-order {
		width: 170px;
	}
	.checkbox_custom .input-control__indicator {
		background-color: $tc;
		border: none;
		@include rounded-corners(3px);
		&:after {
			border-color: $white;
			top: 1px;
			left: 6px;
			width: 6px;
			height: 12px;
		}
	}
	#button-order {
		margin-top: 25px;
	}
}




























