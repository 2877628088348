@media (max-width: $screen-lg-max) {
	.pav-megamenu .subcat .catgrid {
		flex-wrap: wrap;
		&_element {
			flex: 0 1 25%;
			text-align: center;
		}
	}
	.homebuilder .catgrid_element {
		flex: 1 25%;
	}
	.product-grid .product-block, .products-owl-carousel .product-block {
		width: calc(100% / 3);
	}
}

@media (max-width: 1300px) {
	.newsletter .fl_img {
		display: none;
	}
}
.pav-megamenu .navbar-nav > li a {
		padding: 25px 10px;
		font-size: 14px;
		font-weight: bold;
	}
.pav-megamenu .navbar-nav .dropdown-menu > li a {
		padding: 15px 10px;
	}
.pav-megamenu .navbar-nav .dropdown-menu .list-unstyled{
		padding: 15px 0 55px 0;
	}
.pav-megamenu .navbar-nav .dropdown-menu .list-unstyled img {
	max-width: 80px;
}
.pav-megamenu .navbar-nav > li a:hover,
.pav-megamenu .navbar-nav > li a:focus {
		color: $tc;
	}
.pav-megamenu .navbar-nav > li .dropdown-menu a:hover,
.pav-megamenu .navbar-nav > li .dropdown-menu a:focus {
		color: $wh;
	}
@media (max-width: $screen-md-max) {
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li {
		&:first-child {
			& > a {
				padding-left: 0;
			}
		}
		&:first-child {
			& > a {
				padding-right: 0;
			}
		}
		& > a {
			padding: 25px 10px;
			font-size: 14px;
		}
		& > a:hover,
		& > a:focus
		 {
			color: $tc;
		}
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
		.row .cheaprow {
			padding-right: 50px;
		}
		.row .product-row {
			flex-wrap: wrap;
			.product-col {
				flex: 1 0 100%;
				&.product-buttons {
					display: flex;
					margin-top: 15px;
				}
				.quantity-row {
					justify-content: flex-start;
				}
				.product-buttons {

				}
			}
			.btn-container {
				padding-bottom: 0;
				margin-right: 20px;
			}
		}
	}
	.header-top .topcontacts.phone {
		flex: 0 1 230px;
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 170px;
		}
		.nav-search {
			margin-bottom: 7px;
		}
		#searchblock {
			width: 300px;
		}
	}
	.category_widget {
		.type1 .img h3 {
			font-size: 18px;
		}
		.type2 .img {
			h3 {
				padding: 20px;
				font-size: 28px;
			}
		}
	}
	.product-grid .product-block, .products-owl-carousel .product-block {
		width: calc(50%);
	}
	.pav-megamenu .dropdown-menu li a {
		padding: 10px 15px 10px 0;
		font-size: 16px;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.topcontacts {
		&.hw {
			.nav > li > a {
				padding: 10px 6px;
				font-size: 13px;
			}
		}
	}
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	.homebuilder .catgrid_element {
		display: none;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	.holiday_works {
		left: 50%;
		top: 65px !important;
	}
	#certificat-banner {
		margin: 0;
		padding: 0;
		.img {
			margin: -26px 0 -165px 0;
		}
		.text {
			h3 {
				font-size: 24px;
			}
			p {
				font-size: 14px;
			}
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	#offcanvasmenu .catgrid_element img {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(50%);

			.action > div {
				padding-right: 2px;
			}
			.product-meta {
				margin-bottom: 25px;
				.name {
					margin: 0 20px;
				}
				.price {
					padding: 15px 0;
					margin: 0 20px;
					padding-top: 0;
					&:before {
						top: -5px;
					}
				}
			}
		}
	}
	.header-top {
		.topcontacts, .topcontacts.phone {
			flex: 0 0 250px;
			padding-left: 0;
			.media-title {
				padding: 7px 10px;
			}
		}
		.topcontacts svg {
			height: 40px;
		}
	}
	#powered .copyright {
		font-size: 14px;
	}
	.s24 {
		width: 90px;
	}
	.header-bottom {
		.main-menu {
			.pav-megamenu {
				.btn-primary {
					padding: 0 13px;
					&:hover {
						background: $tc;
					}
					span {
						font-size: 18px;
						vertical-align: text-bottom;
					}
				}
			}
		}
		.nav-search {
		}
		.logo-container {
			flex: 1 1 auto;
			text-align: center;
		}
		#searchblock {
			width: 700px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.h1_container h1 {
		padding: 0 15px 21px 15px;
	}
	.category_widget .type2 .img h3 {
		padding: 15px;
		font-size: 22px;
	}
	.cat_all {
		padding: 0 0 0 15px !important;
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 0 5px;
				font-size: 18px;
				line-height: 14px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {
				i:before {
					content: '\f105'
				}
				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 25px);
					display: inline-block;
				}
				&.opened {
					ul {
						display: block;
					}
					i:before {
						content: '\f107'
					}
				}
			}
			ul.children {
				li {
					padding: 0;
				}
			}
		}
	}
	#adv_main {
		padding: 10px 0;
		flex-wrap: wrap;
		.advmain {
			flex: 1 1 50%;
			padding: 10px 25px
		}
	}
	.category_widget {
		.type1 {
			h3 {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.product-info {
		.title-product {
			font-size: 30px;
			padding: 20px 0 20px 0;
		}
		.cheaprow {
			& > div {
				margin-right: 0;
			}
		}
		.row {
			.cheaprow {
				padding-right: 0;
			}
			.list-unstyled {
				flex-wrap: wrap;
				width: auto;
				justify-content: flex-start;
				margin: 15px 0 0 0;
				&.attrow li {
					font-size: 20px;
					margin-bottom: 0;
				}
				&.cheaprow {
					li {
						width: 100%;
					}
				}
				&.pricerow {
					margin-bottom: 30px;
					.price {
						margin-bottom: 20px;
					}
				}
				&.buttonrow {
					.btn {
						margin-bottom: 15px;
					}
				}
			}
			.product-row .product-col.product-buttons {
				flex-wrap: wrap;
				.btn-container:first-child {
					margin-bottom: 15px;
				}
			}
		}
	}
	.refine-search ul li a span, .refine-search ol li a span {
		font-size: 14px;
	}
	.desc-header-related {
		text-align: left !important;
		& > .container {
			position: static;
		}
		.scroll-button {
			right: 30px;
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	 .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	#certificat-banner {
		padding: 20px 0;
		.img {
			display: none;
		}
	}
	.table-description {
		margin-left: 0;
	}
	.category_widget .type1 .img {
		margin-bottom: 15px;
		h3 {
			height: auto;
		}
	}
	.holiday_works {
		z-index: 999;
		left: calc(50% - 76px);
		top: calc(31vw + 100px) !important;
	}
	.product-block .bottom .action {
		&.btn-cart {
			left: 0;
		}
		&.btn-oneclick {
			display: none;
		}
	}
	.category_widget .type2 .img h3 {
		white-space: normal;
		font-size: 5vw;
	}
	.logo-container {
		width: 140px;
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	#powered .inner {
		justify-content: center;
	}
	#adv_main {
		padding: 10px 0;
		flex-wrap: wrap;
		.advmain {
			flex: 0 0 100%;
			padding: 10px 25px
		}
	}
	#tab-review .desc-pane {
		padding: 15px 28px;
	}
	.desc-header-related {
		& > .container {
			text-align: center;
			position: relative;
		}
		.desc-header {
			margin: 0 15px;
			padding-bottom: 0;
		}
		.scroll-button {
			position: relative;
			right: 0;
			top: 0;
			float: right;
			margin: 15px;
		}
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, &.tr {
			display: block;
			border: 0;
			width: 100%;
		}
		tr {
			display: block;
			td {

				&:first-child {
					border-top: 0;
				}
				div.th-title {
					display: block;
					position: absolute;
					left: 10px;
					width: 38%;
					padding-right: 10px;
					text-align: right;
					top: 50%;
					@include transform(translateY(-50%));
					font-weight: bold;
					text-transform: uppercase;
				}
				&.no-title {
					padding-left: 20px;
					text-align: center;
				}
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}

	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	#coupon_form {
		width: 100%;
		display: inline-block;
		top: 1px;
		position: relative;
		.form-group {
			display: inline-block;
		}
		#button-coupon {
			top: -1px;
		}
	}
	.header-top {
		.topcontacts {
			display: -webkit-box;
			   display: -webkit-flex;
			   display: -ms-flexbox;
			   display: flex;
			flex: 1 1 100%;
		}
	}
	.header-bottom {
		> .container {
			flex: 1;
		}
		.header-panel {
			flex: 0 1 200px;
			top: calc(50% - 12px);
		}
		.main-menu {
			flex: 1;
		}
		.headercart .cart-total {
			display: none;
		}
		#searchblock {
			width: calc(100vw - 68px);
			height: 72px;
			top: -24px;
		}
	}
	.logo-store {
		font-size: 12px;
		img {
			max-height: 40px;
		}
	}
	.canvas-menu {
		top: -60px;
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
		}
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.h1_container h1 {
		padding: 13px 15px;
		font-size: 32px;
	}
	.producttabs .tab-header {
		height: auto;
	}
	.product-info .tab-content {
		.desc-header-wrapper .desc-header {
			font-size: 26px;
		}
		#tab-description, #tab-downloads {
			font-size: 14px;
			ul {
				padding-left: 10px;
			}
		}
		#tab-description .desc-pane {
			padding: 15px;
		}
		.attribs {
			.arow {
				div {
					font-size: 14px;
					padding: 5px 10px;
				}
				.asingle {
					padding: 5px 10px;
				}
				.acol {
					padding-left: 10px;
				}
			}
		}
	}
	.product-info .image-container {
		padding-bottom: 0;
	}
	.product-filter > .container {
		padding: 15px 30px;
		.sortlimit > div {
			& > span {
				font-size: 14px;
			}
			.selectric .label {
				padding: 8px 0;
				font-size: 14px;
			}
			&.limit {
				display: none;
			}
		}
	}
	.category_widget .type2 .img {
		height: 270px;
		background-size: cover;
		padding-bottom: 0;
		background-position: right;
	}
	.producttabs .tab-header span {
		padding: 10px 0;
	}
	.producttabs .tab-header span,
	.category_widget .type2 .img h3,
	.category_widget .type2 .descr {
		font-size: 16px;
	}
	.widget-products.producttabs {
		padding-top: 0;
	}
	.producttabs .owl-carousel-play {
		margin-top: 0px;
	}

	.product-info .row .cheaprow {
		padding-right: 15px;
	}
	.fancy_holiday_works {
		width: 90% !important;
		.fancybox-inner {
			width: 100% !important;
		}
	}
	.holiday_table {
		width: 100%;
		td {
			font-size: 13px;
		}
	}
	.fancybox-skin {
		padding: 10px !important;
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 30px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 11px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.logo-store a {
		margin: 0 auto 0 40px;
	}
	.newsletter {
		.nl_header {
			font-size: 28px;
		}
	}
}

@media (max-width: 570px) {
	.owl-dots {
		margin-top: 17px;
	}
	#powered > .container {
		padding: 0;
	}
	.header-top .topcontacts.worktime {
		display: none;
	}
	.header-top .topcontacts.phone {
		flex: 0 0 155px;
	}
	.header-bottom .nav-search {
		flex: 0 1 30px;
		margin-right: 15px;
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.catwidget_header h2 {
		font-size: 40px;
		margin-top: 30px;
		margin-bottom: 15px;
	}
}

@media (max-width: 510px) {
	.product-info .row .list-unstyled.pricerow .price {
		flex: 0 0 100%;
	}
	.producttabs .owl-item {
		padding: 15px 0;
	}
	.h1_container h1 {
		font-size: 22px;
	}
}

@media (max-width: $screen-xs) {
	.category_widget .type2 .img h3 {
		font-size: 24px;
	}
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.footer-center {
		font-size: 14px;
	}
	.product-info .row .list-unstyled li.price {
		flex: 1 1 100%;
	}
	.product-grid {
		.product-block {
			width: 100%;
			.product-meta .name {
				margin: 15px 15px 0 15px;
			}
		}
	}
	#partnerpricemodule {
		margin:0;
		padding: 0;
		h3 {
			font-size: 20px;
		}
		button {
			padding: 0 12px;
			font-size: 18px;
		}
	}
	.header-bottom {
		.product-compare-header, .headercart {
			position: absolute;
			top: -65px;
		}
		.product-compare-header {
			right: 50px;
			svg {
				fill: $wh;
			}
		}
	}
	#powered {
		.payment-img {
			img {
				max-height: 60px;

				&:first-child {
					display: none;
				}
			}
		}
	}
	.payment2row {
		display: block;
		margin: 8px 0;
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.mob_hidden_phone {
		display: none;
	}
	#coupon_form {
		width: calc(100% - 110px);
	}
	#button-coupon {
		font-size: 15px;
		padding: 0 6px;
	}
}


.tcarousel .owl-nav,
.owl-related .owl-nav {
	position: absolute;
	top: -30px;
	width: 100%;
	border-bottom: solid 1px white;
	.owl-prev, .owl-next {
		@include fade();
		border: solid 1px $wh;
		font-size: 15px;
		font-weight: 600;
		padding: 9.5px 2px;
		box-sizing: initial;
		text-align: center;
		height: auto;
		top: -35px;
		z-index: 5;
		&:hover {
			color: $tc;
			border: solid 1px $bk;
			z-index: 10;
		}
	}
	.owl-prev {
		left: auto;
		right: 35px;
	}
	.owl-next {
		right: 0;
	}

}

	.pav-megamenu .navbar-nav > li > .dropdown-menu {
		z-index: -1;
		left: 0;
		width: 100%;
		margin-left: 0!important;
	}

	.pav-megamenu .navbar-nav > li:hover > .dropdown-menu,
	.pav-megamenu .navbar-nav > li:focus > .dropdown-menu,
	.pav-megamenu .navbar-nav > li:active > .dropdown-menu
	 {
		z-index: 70;
	}
	.navbar,
	.pav-megamenu .navbar-nav > li,
	.header-menu > .container
	{
		position: initial;
	}
	.header-menu {
		position: relative;
	}
	.pav-megamenu .dropdown-menu li {
		display: inline-block;
		    width: 24.6%;
		    padding: 0;
	}
	.pav-megamenu .navbar-nav .dropdown-menu li span {
	    font-weight: 400;
	    white-space: initial;
	    display: inline-block;
	    width: 70%;
	    vertical-align: middle;
	}

	.pav-megamenu .navbar-nav > li .dropdown-menu a:hover,
	 .pav-megamenu .navbar-nav > li .dropdown-menu a:focus {
		color: $tc;
	}
	.pav-megamenu .dropdown-menu li:hover {
		background: none;
	}


#adv_main .advmain object {
	max-width: 85px;
}
#adv_main .advmain object > svg {
	fill: $tc;
}
.widget-products.producttabs {
	padding: 80px 0;
}
.widget-products.producttabs.related_product {
	padding-top: 0;
}
.tab-v6.space-top-20.container {
	padding-bottom: 80px;
}
h1, .h1 {
    margin-bottom: 40px;
}




@media (min-width: 1200px) {
	.mfilter-button-reset {
	    width: 211px;
	}
	#news_list .news_item .news_descr p {
		min-width: 330px;
	}
}

@media (max-width: 1199px) {
	.header-top {
		height: auto;
		padding: 5px 0;

		.topcontacts {
			flex-wrap: wrap;
		}
	}
	.holiday_works {
		position: unset !important;
		margin: 8px 0 0 0 !important;
		display: block;
		width: 100%;
		text-align: left;
	}
	.product-block .bottom .action.cart-nostock {
	    font-size: 9px;
	}

	.pav-megamenu .navbar-nav > li > a {
		font-size: 12px;
		padding: 25px 5px;
	}
	.logo-store span {
		max-width: 170px;
	}
	.header-bottom #searchtop input.form-control {
		width: 210px;
	}
	.widget-products.producttabs {
	    padding: 40px 0;
	}
	.review_block_home .one_review .img img {
	    max-width: 107px;
	}
	.review_block_home .one_review .price {
	    margin: 5px 0;
	}
	.review_block_home .one_review .name {
	    height: auto !important
	}
	.newsletter .nl_email input {
	    max-width: 370px;
	}
	.footer-center .footer-name span {
	    white-space: normal;
	    text-align: left;
	    line-height: 1.5;
	    display: block;
	    margin-top: 3px;
	    font-weight: 300;
	}
	.catwidget_header .row-level-1,
	.block_warranty .row-level-1,
	.main_nl .row-level-1,
	.block_slider .row-level-1,
	.producttabs_home .row-level-1
	 {
		margin: 0;
	}
	.mfilter-button-bottom a{
		font-size: 14px;
	}
	.product-list .product-label.sale {
		right: 0;
	}
	.product-list .product-label.new {
		left: 0
	}
	.product-list .product-block .quickview {
		right: calc(50% - 82px);
	}
	.product-info .product-view .cheap_invoice svg {
		height: 100%;
	}
	#checkout-form {
		max-width: 660px;
		.checkout-f {
			margin: 5px 0 25px 0;
		}
		.col-sm-8 {
			width: 100%;
			display: inline-block;
		}
	}
	.buy_products {
		justify-content: flex-end;
	}
	.table {
		&.shopping-cart-table {
			max-width: 100%;
			margin-bottom: 50px;

			tbody {
				.column-name {
					font-size: 14px;
					width: 320px;
				}
			}
			tr {
				td {
					&.column-price {
						width: 162px;
					}
					&.column-total {
						width: 162px;
						text-align: right;
						padding-right: 15px;
					}
				}
			}
		}
	}
	.block_slider .col-lg-12.col-md-12.col-sm-12.col-xs-12 {
		padding:0;
	}

	#news_list {
		.news_item.col-md-4 {
			.news_descr {
				min-height: 130px;
			}
		}
	}
}

@media (max-width: 1199px) and (min-width: 768px){
	#checkout-form #checkout-f {
		margin: 0 0 25px 0;

	}
	#buy_form_container .total {
		margin-bottom: -255px;
	}
}


@media (max-width: 1199px) and (min-width: 992px){



	.product-block .bottom .btn-oneclick {
	    font-size: 13px;
	}
	.category_widget .type2 .img h3 {
	    padding: 28px 30px 10px 30px;
	}
	.producttabs_home .col-lg-12.col-md-12,
	.block_warranty .col-lg-12.col-md-12 {
		padding: 0;
	}
	.product-info .product-view .product_advantage .two_lines2 svg {
		float: left;
	}
	.product-info .product-view .price-profit {
		margin-left:0;
	}
	.product-info .product-view #button-cart {
    	padding: 0 37px;
   	 	margin-top: 20px;
	}

}

@media (min-width: 992px) {
	#menu .fa {
		display: none;
	}
	.main_nl .row-inner {
		padding: 0;
	}
	#footer, #powered{
		display: inline-block;
		width: 100%;
		float: left;
	}
	.pav-megamenu {
		position: static;
	}
	.mfilter-button-reset {
	    margin-bottom: 15px;
	    width: 190px;
	    margin-left: 0;
	}
	.mfilter-button{
		a{
			background: #F5F5F5;
			color: #222222;
		}
	}
	.filter_on {
		display: none;
	}
	.pav-megamenu span.bg.pull-right{
		display: none;
	}
	.price .price-new, .product-list .listprice .price-new {
		font-size: 24px;
	}
	.news_block {
		width: 970px;
	}

}

@media (max-width: 991px) {
	.mfilter-button{
		a{
			width: 60%;
			margin-left: 4%;
			font-weight: inherit;

		}
		.mfilter-button-reset {
			background: #F5F5F5;
			color: #222222;
			width: 30%;
		}
	}

	.mfilter-box {
		box-shadow: none;
	}
	.product-grid .product-block .price, .products-owl-carousel .product-block .price {
		width: auto;
	}
	.product-filter .sortlimit .limit {
		display: none;
	}
	.product-filter .sortlimit {
		flex:0;
	}
	.mfilter-free-container {
		margin-left: -967px;
	}
	.mfilter-free-container {
	    position: fixed;
	    max-width: 100%;
	    height: 100%;
	    width: 100%;
	    padding: 0;
	    border: none;
	    top: 0;
	    z-index: 9999999;
	    background: white;
	}
	.mfilter-button-bottom {
		position: fixed;
		bottom: -100px;
		left: 0;
		width: 100%;
		background: white;
		z-index: 9999;
	    -webkit-transition: all 0.5s ease-out;
		-o-transition: all 0.5s ease-out;
		transition: all 0.5s ease-out;
	}
	.mfilter-button-bottom .mfilter-button-reset {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		font-size: 13px;
	}
	.canvas-menu svg{
		width: 22px;
		float: left;
		margin-top: 4px;
	}

	.canvas-menu span {
		@include fade();
	}
	.canvas-menu {
		@include fade();
	    position: relative;
	    top: 0;
	    left: 0px;
	    margin: 13.5px 0px;
	}
	.header-menu #menu,
	.header-top .megamenu,
	#partnerpricemodule p br,
	.header-bottom .header-panel .phone,
	.header-bottom  .header-panel .search_block {
		display: none;
	}
	.menutoggle.header-bottom .search_block{
		display: block;
	}
	.menutoggle.header-bottom {
		height: auto;
		width: 510px;
		display: inline-block;
		float: right;
		margin-top: 10px;

		#searchtop_mobile input.form-control {
			background: $wh;
			width: 100%;
		}
		#searchtop_mobile .btn-search {
			background: $wh;
		}
	}
	.header-top .topcontacts svg {
	    height: 24px;
	    width: auto;
	    margin-top: 5px;
	}
	.header-top .topcontacts .worktime svg {
	    margin-top: 0;
	}
	.header-top .topcontacts .media-body a {
	    color: $wh !important;
	}
	.header-top .topcontacts span a:first-child {
		margin-right: 10px;
	}
	#adv_main .advmain {
	    flex: 1 1 25%;
	    padding: 50px 0 0 0;
	}
	#partnerpricemodule .img {
		margin-right: -45px;
		margin-left: 45px;
	    height: 358px;
	    background-position: bottom;
	}
	#partnerpricemodule {
		height: 290px;
	}

	.block_slider .container,
	.bigcat,
	 .main_nl .row-inner {
		width: 100%;
		padding: 0;
	}
	.category_widget .type2 .img h3 {
	    padding: 30px 0px 15px 30px;
	}
	.review_block_home .one_review .name {
		font-size: 12px;
		max-width: 210px;
	}
	.review_block_home .one_review .author_date {
	    font-size: 12px;
	    color: $bk;
	}
	.review_block_home .one_review .author_date .date {
	    margin-right: 15px;
	    float: right;
	}
	.newsletter .nl_header {
	    font-size: 24px;
	}
	.newsletter .nl_email input {
	    max-width: 270px;
	}
	.newsletter .checkbox_custom .input-control {
		font-weight: 300;
	}
	#pavo-footer-center .container .footer-left {
	    float: left;
	    margin: 0 auto 30px auto;
	    max-width: 145px;
	}
	.footer-center .footer-name span {
	    white-space: nowrap;
	    color: #E5E5E5;
	    margin-top: 13px;
	}
	.navbar-collapse.collapse {
	    display: block !important;
	    visibility: visible !important;
	    height: auto !important;
	    padding-bottom: 0;
	    overflow: visible !important;
	    padding: 0px 30px 0px 22px;
	}
	.offcanvas-footer a {
		color: $bk;
		padding: 0px 15px;
	}
	.sidebar-offcanvas {
		z-index: 999;
	}
	.header-menu .canvas-menu:focus,
	.header-menu .canvas-menu:hover,
	.sidebar-offcanvas .cat:focus,
	.sidebar-offcanvas .cat:hover
	 {
		color: $tc;
		fill: $tc;
	}
	.header-bottom .product-compare-header {
	    height: 74px;
	}



	.producttabs_home .col-xs-12,
	.block_warranty .col-xs-12
	 {
		padding: 0;
	}
	.review_block_home .container{
		width:100%;
	}


	.catwidget_header .row-level-1,
	.block_warranty .row-level-1,
	.main_nl .row-level-1,
	.block_slider .row-level-1,
	.producttabs_home .row-level-1
	 {
		margin: 0;
	}
	.mfilter-heading-text svg {
		float: right;
		margin-top: 10px;
		margin-right: 10px;
	}
	.mfilter-box .box-content {
		padding: 0 40px;
		padding-bottom: 100px;
	}
	.search_ctrl #input-search {
	    margin-bottom: 20px;
	}
	h1, .h1 {
	    font-size: 24px;
	    margin-bottom: 30px;
	}
	.price .price-new, .product-list .listprice .price-new {
	    font-size: 18px;
	}
	.mfilter-box .box-content .mfilter-heading-text > span, .mfilter-button a {
	    font-size: 16px;
	}

	#mfilter-opts-price-min, #mfilter-opts-price-max, .mfilter-box .mfilter-opts-slider-min, .mfilter-box .mfilter-opts-slider-max {
		min-width: 150px;
		max-width: 150px;
		font-size: 16px;
	}
	.ui-slider-handle.ui-state-default span {
	    color: #9E9E9E;
	    bottom: -19px;
	    font-size: 12px;
	}
	.product-info .product-view .right {
	    padding-left: 0;
	}
	.product-info .button_top {
	    margin-top: 15px;
	    margin-bottom: 15px;
	}
	.product-info .product-view .product_advantage {
	    margin-top: 20px;
	}
	.product-info .product-view .title-product {
		font-size: 24px;
	}
	.product-info .quantity-adder .quantity-number input {
		width: 122px;
	}
	.product-info .quantity-adder {
		max-width: 155px;
	}
	.product-info .dop_text .btn-primary {
		margin-bottom: 3px;
		margin-left: 65px;
	}
	.product-info .attributes li b {
	    margin-left: 5px;
	}
	.image-container .product-row {
		margin-bottom: 30px;
	}
	.product-info .attributes li {
	    margin-top: 10px;
	}
	#image-additional {
    	margin: 0 10px;
	}
	.imgthumbs.owl-carousel .owl-item img {
	    display: block;
	    width: 100%;
	    max-width: 100%!important;
	}
	.imgthumbs .owl-prev {
	    left: -15px;
	}
	.imgthumbs .owl-next {
	    right: -25px;
	}
	.image.thumbnail {
		display: none;
	}
	.kit_block.product-grid .producs_block {

		.product_list_item {
			border: none;
		}
		.kit_total {
			background: none;
			.price {
				padding: 20px 20px 0 20px;
			}
			.bottom {
				margin: 30px 0 20px 0;
			}
			.price-old {
				margin-bottom: 20px;
				margin-top: 10px;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
	#checkout-form {
		max-width: 480px;
		.checkout-f, h2 {
			font-weight: 600;
			font-size: 14px;
		}
		label {
			font-size: 14px;
		}
		#button-order {
			margin-bottom: 80px;
		}
		strong {
			    margin: 10px 0 15px 0;
		}
		#shipping_methods {
			p {
				margin: 0;
			}
			.tk-comment p.time {
				margin: 10px 0 20px 0;
			}
		}

	}
	#buy_form_container .total {
	    width: 220px;
	    padding: 15px;
	    margin-top: 0;
	}
	#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	    font-size: 16px;
	    margin: 5px 0;
	}


	.success.product-info {
		width: 630px;
		margin-left: -315px;
		.add {
			margin-bottom: 40px;
		}
		.go_cart {
		    float: left;
		    margin-top: 1px;
		     a {
		        width: 215px;
		        font-size: 16px;
		    }
		}
		.quantity-adder {
			max-width: 150px;
		}
		.close {
		    top: 35px;
		}
		.info .price-new {
		    padding: 5px 0 35px 0;
		}
		#products.product-list {
		    padding-bottom: 0px;
		    .product-block  {
		    	.block-img {
		    		align-self: center;
		    	}
		    	.listprice {
		    		align-self: center;
		    		    padding: 0;
		    		button {
		    			width: 100%;
		    		}
		    	}
		    }

		    .add {
		    	margin-bottom: 30px;
		    	margin-top: 30px;
		    }
		    .price-new {
		        font-weight: 500;
		        width: 100%;
		        display: inline-block;
		        margin: 5px 0;
		    }
		}
		.title_p {
			max-width: 270px;
		}

	}
	#mfilter-free-container-0 {
		max-height: 100% !important;
		position: relative;

	}
	.mfilter-content > ul {
		padding-bottom: 80px;
	}
	.mfilter-free-container {
		overflow: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling:touch;
	}
	.on_filter .mfilter-button-bottom {
		bottom: 0px;

	}
	#news_list {
		.news_item.col-md-4 {
			.news_descr {
				min-height: auto;
			}
			h3 {
			    min-height: auto;
			}
		}
	}



	.footer-center .main-footer .box {
	    margin-right: 30px;
	}








}

@media (max-width: 991px) and (min-width: 768px){
	.main_nl .row-inner .col-xs-12:last-child {
			padding-right: 0;
	}
	.category_widget .type2 .img {
	    padding: 10px 0px 40px 100px;
	}
	.newsletter p {
	    font-weight: 300;
	    max-width: 298px;
	}
	.header-menu .canvas-menu > span{
		display: inline-flex;
		float: left;
		font-size: 14px;
		margin-left: 15px;
		text-transform: uppercase;
		padding-top: 8px;
	}
	#accordion h3 {
		margin-top: 0;
	}
	.header-bottom .search_block {
	    margin-right: 0;
	}
	.header-menu {
	    height: 56px;
	}
	.header-bottom #searchtop_mobile .btn-search {
	    padding: 0 10px;
	}
	.block_slider .pav-inner.container{
		padding: 0;
	}
	.product-info .nav-tabs > li > a {
		font-size: 18px;
	}
	.product-info .tab-v6.space-top-20 {
		margin-top: 55px;
	}
	.product-info .tab-content #tab-downloads ul li {
		font-size: 16px;
	}
	.success.product-info .add br {
		display: none;
	}
	.scroll {
	    max-height: 204px;
	}

}

@media (min-width: 768px) {
	.logo_mobile {
		display: none;
	}
	.kit_block.product-grid .producs_block .product-block {
		width: 22%;
	}
	.kit_block.product-grid {
		.producs_block_left {
			width: 74%;
			@include flex();
			flex-wrap: wrap;
			align-content: center;
			justify-content: center;
			.product-block {
			    width: 28%;
			    margin: 20px 0px;

			}
			.plus {
				width: 8%;
			}
		}
	}

}

@media (min-width: 768px) {

	.qwpage {
		margin: 0 15px;
	}
}

@media (max-width: 854px) {

	.qwpage .product-info .compare_button {
	    width: 100%;
	    display: inline-block;
	    float: none;
	    margin-bottom: 10px;
	}
	.qwpage .product-info .row .list-unstyled.pricerow {
	    margin-bottom: 20px;
	}
	.qwpage .quick-view .product-info .row .buttonrow {
	    display: inline-block;
	    width: 100%;
	    margin: 0;

	}
	.qwpage .quick-view .product-info .row .pricerow {
	    margin: 15px 0 0 0;
	}
	.qwpage .quick-view .product-info .row #button-cart {
	    width: 100%;
	}
	.qwpage .quick-view .product-info .row #button-cart-credit {
	    margin-left: 23px;
	    cursor: pointer;
	    margin: 20px 0px 0 0;
	    display: inline-block;
	    width: 100%;
	    text-align: center;
	}
	.qwpage #image-additional {
	    margin: 0px 10px;
	}
	.qwpage .product-info .quantity-adder {
	    width: 155px;
	    display: inline-block;

	}

	html.qwpage {
		overflow: visible;
	}
	.fancybox-skin .close_a {
		top: -30px;
		right: 10px;
	}
	.fancybox-skin .close_a svg {
		fill: $wh;
	}
	.fancybox-opened{
		padding-top: 25px;
	}
}

@media (max-width: 767px) {
	.cheaper-products {
		margin-bottom: 20px;
	}
	.total_wrapper {
		width: 100%;
		height: auto;

		.total {
			padding: 0;
			box-shadow: none;
			border-left: 0;
			border-top: 0;
			border-right: 0;
			border-bottom: 1px solid $lg;
		}
	}
.product-block .quickview.iframe-link {
	display: none;
}
		#image-additional {
	    	margin: 0 30px;
		}
		.imgthumbs .owl-prev {
		    left: -30px;
		}
		.imgthumbs .owl-next {
		    right: -30px;
		}
	.mfilter-button{
		a, .mfilter-button-reset {
			width: 45%;

		}
		.mfilter-button-reset {
			margin-left: 0;

		}
		a span {
			display: none;
		}
	}
	.product-filter .sortlimit .sort {
		margin-right:0;
	}
	.hidden_search #searchtop_mobile svg {
	    margin-top: 6px;
	    fill: $tc;
	}
	.hidden_search .search_block {
	    margin-right: 0;
	}
	.menutoggle.header-bottom {
	    width: 100%;
	    position: absolute;
	    right: 0;
	    margin-right: 0;
	    margin-top: 2px;
	}
	.pav-megamenu * {
	    @include fade();
	}
	.hidden_search.no_active {
		width: 35px;
		overflow: hidden;
		float: left;
		left: 18%;
		margin-top: 5px;
		#searchtop_mobile input.form-control {
			background: $lg;
			width: 100%;
			@include fade();
		}
		#searchtop_mobile .btn-search {
			background: $lg;
		}
	}




	#adv_main,
	.review_block_home,
	.owl-related .owl-nav,
	.tcarousel .owl-nav,
	#partnerpricemodule .img   {
		display: none;
	}
	#sidebar-main .col-xs-12 {
		padding: 0;
	}

	.producttabs .tab-header {
		background: none;
	}

	.header-top .topcontacts .media-body.phone span {
		display: inline-block;
		float: left;
	}
	.tcicon {
		display: inline-block;
		float: left;
		min-width: 26px;
	}



	.header-top .topcontacts .media-body.phone span a {
		display: inline-block;
	    width: 100%;
	    font-size: 9px;
	    float: left;
	}
	.header-top .topcontacts .media-body.phone {
		width: 105px;
	}
	.header-top .topcontacts .media-body.worktime {
		font-size: 9px;
		width: auto;
		max-width: 120px;
	}
	.header-top .topcontacts .media-body.worktime .mobile_worck span {
	    width: 100%;
	    display: inline-block;
	}
	.header-top .topcontacts .media-body.worktime svg {
		@include size(36px, 17px);
		margin-top: 0;
		fill: $wh;
		margin-right: 5px;
	}
	.header-top .topcontacts .media-body.phone svg {
		fill: $wh;
		margin-right: 7px;
		@include transform(rotate(-27deg));
	}
	.header-top .logo_mobile {
		max-width: 90px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;

		align-items: center;
		-webkit-box-align: center;
	}
	.canvas-menu {
		left: 0;
		margin: 11.5px 0px;
		fill: $tc;
	}


	.canvas-menu svg {
	    margin-top: 0px;

	}
	.header-bottom .product-compare-header {
		position: absolute;
		top: 0;
		right: 25%;
		height: 43px;
		z-index: 9;
		margin: 0 !important;
	}
	.header-bottom .product-compare-header svg {
		fill: $tc;
	}
	.header-bottom .headercart {
		position: absolute;
		top: 9px;
		z-index: 9;
		right: 0;
	}
	.header-bottom .headercart .cart-count {
		right: 10px;
	}
	.header-bottom .wrap-cart svg {
	    width: 28px;
	    height: 28px;
	    fill: $tc;
	}
	.header-bottom .svg_header,
	.header-bottom .badge {
		float: left;
	}
	.header-bottom .header-panel {
	    width: 100%;
	    top: 0;
	}
	.header-bottom .product-compare-header a {
	    margin-top: 8px;
	}
	.header-bottom .product-compare-header .badge {
		margin-top: 4px;
		font-size: 16px;
	}

	.sidebar-offcanvas .cat {
		font-size: 16px;
	}
	.sidebar-offcanvas .navbar-nav {
	    margin: 0;
	}
	.sidebar-offcanvas .panel-offcanvas {
	    width: 100%;
	    height: 70%;
	}
	.navbar-collapse.collapse {
	    padding: 0px 15px;
	}
	.sidebar-offcanvas .panel-heading {
	    padding: 30px 15px 10px 33px;
	}
	.offcanvas-heading svg {
	    margin-top: -21px;
	}
	.navbar-nav .open .dropdown-menu {
	    min-width: auto;
	}
	.navbar-nav .dropdown-menu .container{
	    padding: 0;
	}
	.header-bottom .headercart {
		width: 58px;
	}
	.catwidget_header {
		display: none;
	}

	.main_nl.col-xs-12{
	    padding: 0 15px;
	}

	#sidebar-main .main_nl .col-xs-12 {
		padding: 0 15px;
	}
	#sidebar-main .main_nl .col-xs-12 + .col-xs-12{
		padding: 0;
	}
	#sidebar-main .newsletter .nl_header {
	    font-size: 18px;
	    line-height: 22px;
	}
	#sidebar-main .newsletter .nl_email input {
	    max-width: 225px;
	}
	#sidebar-main .main_nl .main_articles .one_news {
	    padding: 30px 15px 40px 15px;
	}
	.category_widget .type2 .img .descr,
	.category_widget .type2 .img a {
		padding: 0 15px;
	}
	.category_widget .type2 .img h3 {
	    padding: 30px 0px 15px 15px;
	}
	#pavo-footer-center .container .footer-left {
	    margin: 0;
	}
	.footer-center .box .box-heading {
	    font-weight: 500;
	}
	.footer-center .main-footer .box {
		margin-top: 20px;
	}
	#partnerpricemodule .text{
		padding: 0;
	}
	.tp-simpleresponsive .slotholder *, .tp-simpleresponsive img {
		background-position: left !important;
	}

	.menutoggle.header-bottom #searchtop_mobile input.form-control {
	    margin-top: -2px;
	}
	.menutoggle.header-bottom .search_block {
	    display: block;
	    z-index: 9;
	    position: relative;
	}
	.header-menu {
		height: 45px;
	}
	.refine-search .subcat li a span, .refine-search .subcat .subcat_one a span, .refine-search ul li a span, .refine-search ul .subcat_one a span, .refine-search ol li a span, .refine-search ol .subcat_one a span {
		font-size: 10px;
	}
	.product-search label.checkbox-inline {
		margin-top: 20px;
	}
	.product-filter .sortlimit > div .selectric {
		padding-right: 11px;
	}
	.filter_on {
	    padding-top: 4px;
	}
	.mfilter-box .box-content {
		padding: 0;
	}
	#mfilter-opts-price-min, #mfilter-opts-price-max, .mfilter-box .mfilter-opts-slider-min, .mfilter-box .mfilter-opts-slider-max {
	    min-width: 130px;
	    max-width: 130px;
	    font-size: 16px;
	}
	.refine-search .subcat, .refine-search ul, .refine-search ol {
		padding: 0 5px;
	}
	h1, .h1 {
	    font-size: 18px;
	}
	body[class*="page-category"] #content h1 {
		margin-bottom: 10px;
	}

	.row-offcanvas-left {
		overflow: hidden;
	}
	.row-offcanvas-left.active {
		overflow: visible;
	}
	.sidebar-offcanvas {
	    position: fixed;
	    top: 0;
	    left: -100%;
	    width: 100%;
	    -webkit-transition: all 0.25s ease-out;
	    -o-transition: all 0.25s ease-out;
	    transition: all 0.25s ease-out;
	}
	.row-offcanvas-left.active .sidebar-offcanvas {
		left: 0;
	}
	.row-offcanvas-left.active {
		left: 0;
	}
	.sidebar-offcanvas .panel-offcanvas {
	    width: 100%;
	    height: 100%;
	    overflow: scroll;
	}
	.sidebar-offcanvas .bg.pull-right {
		margin-right: 0;
		margin-top: -4px;
		position: relative;
		top: auto;
		right: auto;
		max-width: 10px;
		max-height: 10px;
	}
	.sidebar-offcanvas .dropdown-menu {
		position: relative;
	}
	.sidebar-offcanvas img {
	    max-width: 40px;
	    max-height: 40px;
	}
	.mfilter-heading-text svg {
	    max-width: 10px;
	    max-height: 10px;
	}

	.product-info .cheaprow > div {
		width: 100%;
	}
	.product-info .product-view .title-product {
	    font-size: 18px;
	}
	.image-container .product-row {
	    margin-bottom: 0;
	}
	.product-info .block_price {
	    padding: 0;
	     box-shadow:none;
	    border-radius: 3px;
	}
	.product-info .button_top {
	    margin-top: 20px;
	    margin-bottom: 15px;
	}
	.product-info .pricerow .price .price-new {
	    font-size: 16px;
	}
	.product-info .pricerow .price .price-old {
		font-size: 14px;
		left: 0;
	}
	.product-info .product-view .price-profit {
		padding: 0px 5px;
	}
	.product-info .block_price .stock {
		display: none;
	}
	.product-info .attributes.list-unstyled {
		width: 100%;
	}
	.product-info .attributes.list-unstyled .stock {
		float: right;
		 svg {
		    margin-top: 4px;
		}
		span {
		    font-weight: 400;
		    text-transform: none;
		}
	}
	.product-info .tab-content #tab-description {
	    padding: 0;
	}
	.product-info .tab-content #tab-review {
	    padding: 0;
	}
	#tab-review .desc-pane {
	    padding: 15px 0;
	}
	.product-info .tab-content {
		 .desc-pane {
		    padding: 15px 0;
		}
		#tab-description, #tab-review {
			 .desc-pane {
			    padding: 15px 0;
			}
		}

	}

	.product-info .dop_text .btn-primary {
		margin-left: 0;
		width: 100%;
		margin-top: 15px;
	}
	.product-info .dop_text .adress {
		font-weight: 600;
	}
	.product-info .quantity-adder .quantity-number input {
	    width: 107px;
	}
	.product-info .quantity-adder {
	    max-width: 135px;
	}
	.product-info #button-cart {
	    padding: 0px 15px;
	    line-height: 1;
	}
	.related_product .owl-stage, .related_product .tab-header span,.col_rev .col-sm-10,.col_rev .col-sm-2, .tab-content #tab-downloads ul {
		padding: 0;
	}
	.product-info .tab-content #tab-downloads ul li svg {
		height: 100%;
	}

	#tab-review .btn-primary {
		width: 100%;
		margin: 0 0 15px 0;
	}
	#tab-review .on_rew {
		margin: 0 0 15px 0;
	}
	.tab-v6 .tab-content #tab-attributes {
		margin: 0 -15px;
	}
	.product-info .product-view .custom_mobile span {
		display: none;
	}
	.price .price-new, .product-list .listprice .price-new {
		font-size: 16px;
	}
	.product-grid .product-block .name a, .products-owl-carousel .product-block .name a {
	    font-size: 14px;
	    margin-left: 0;
	}
	.product-grid .product-block .product-meta .price {
	    margin: 0 15px;
	}
	.product-block .bottom {
	    margin: 0 15px;
	}
	.kit_block.product-grid {
		background: $lg;
		padding: 0 15px 35px 15px;
		.tab-header {
		    margin-bottom: 20px;
		    font-size: 16px;
		    padding-bottom: 0;
		    padding-top: 30px;
		}
		.producs_block {
			flex-direction: column;
			.product-meta .name {
			    margin: 0 15px 0 15px;
			    height: auto !important;
			    font-size: 14px;
			}
			.product_list_item  .price {
				margin: 0;
				padding: 0 15px 30px 15px;
				width: 100%;
				display: inline-block;
				span {
					font-size: 16px;
				}
			}
			div.plus, div.total, div {
				width: 100%;
			}
			.kit_total {
				background: white;
				padding: 10px;
				.btn-primary {
					max-width: 130px;
					margin: 0 auto;
				}
				p, span, .price-old {
					text-align: center;
				}
			}

		}
	}
	.product-info .attributes li b span {
		color: $tc;
	}

	.product_advantage  {
		p {
			width: 100%
		}
	}

	#checkout-form {
		width: 100%;
		max-width: 100%;
		display: inline-block;
		margin: 0;
		padding: 0;
		#shipping_methods {
			h2 {
				margin-top: 0;
			}
			.radio_custom {
			    width: 100%;
			}
		}
		label {
			width: 100%;
			margin: 5px 0px 5px 0;
			.title {
				float: left;
				display: inline-block;
				max-width: 79%;
			}
			.input-control__indicator {
				float: left;
				margin-right: 10px;
			}
		}

	}
	#checkout-form .form-control, #buy_form_container .total, #checkout-form .buy_comment .form-control {
		width: 100%;
		min-width: auto;
		max-width: 100%;
	}
	#buy_form_container .buy_products {
		width: 100%;
		table.shopping-cart-table{
			margin-bottom: 10px;
			tr {
				display: inline-block;
				padding: 10px 0;
				border-bottom: 1px solid $lg;
			}
			td {
			    display: inline-block;
			    float: left;
			    padding: 0;
			}
			.column-image {
				max-width: 80px;
				padding: 0;
				margin-right: 5px;
			}
			.column-name {
				width: 70%;
			}
			.column-price {
				width: 70%;
				margin: 7px 0;
			}
			.column-quantity {
				width: 80%;
				float: left;
				margin-left: 85px;
				.btn-block {
					margin: 0 0 10px 0;
				}
			}
			.column-total {
				width: 100%;
				text-align: left;
				.total_title {
					float: left;
					width: 80px;
				}
			}

		}

	}
	.cart_tit_prod {
		font-weight: 600;
		font-size: 14px;
		text-transform: uppercase;
	}
	.success.product-info  {
		width: 100%;
		margin-left: 0;
		padding: 50px 15px 15px 15px;
		left: 0;
		top: 0;
		height: 100%;
		overflow: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
		.success_bg {
			width: 100%;
			padding: 20px;
		}
		.add {
		    margin-bottom: 40px;
		    font-size: 16px;
		}
		.close {
		    top: -25px;
		    right: 10px;
		    fill: white;
		}
		.img_product {
		    width: 100%;
		}
		.info {
		    width: 100%;
		}
		.price {
			display: inline-block;
			float: left;
		}
		.info .price-new {
			padding: 7px 0;
		}
		.quantity-adder {
		    max-width: 136px;
		    float: right;
		}
		.go_cart {
		    width: 100%;
		    margin: 20px 0 0 0;
		    a {
		    	width: 100%;
		    }
		}
		.name {
			margin-bottom: 30px;
		}
		.go_shop {
		    display: inline-block;
		    width: 100%;
		    margin-top: 15px;
		    text-align: center;
		}
		#products.product-list {
			margin-top: 20px;
			padding-bottom: 20px;
			.add {
			    margin-bottom: 25px;
			    margin-top: 20px;
			}
			.block-img {
			    max-width: 80px;
			    width: 100%;
			    img {
			        padding: 10px;
			    }
			}
			.listprice {
				display: none;
			}
			.price-new {
			    width: auto;
			}
			.product-block .title_p {
				padding-bottom: 10px;
				width: 85%;
				max-width: 560px;
				button {
					float: right;
					height: 35px;
					svg {
						width: 25px;
						fill: #fff;
						margin: 10px 5px;
					}
				}
				.name {
				    display: block;
				    margin-bottom: 10px;
				    margin-top: 0;
				}
			}
			.product-block:last-child {
				border: none;
			}
		}
	}
	.modal-body {
		padding: 20px;
		h3 {
			font-size: 16px;
			max-width: 89%;
		}
		.close{
			position: absolute;
			right: 20px;
			top: 20px;
		}
	}

	.scroll {
	    max-height: 190px;
	    width: calc(100% + 10px);
	    padding-right: 5px;
	}
	#news_list .news_img a {
	    width: 100%;
	}
}

@media (min-width: 638px) {

	.success.compare {
		padding: 0;
		div {
			margin-top: 30px;
		}
	}
}

@media (max-width: 637px) {

	.success.compare {
		padding: 0;
		.success_bg {
		    padding: 15px;
		}
		.close {
			top: -25px;
			right: 10px;
			fill: $wh;
		}
		div {
			margin-top: 20px;
		}
	}

	.scroll {
	    max-height: 240px;
	}
}

@media (max-width: 480px) {
	.sidebar-offcanvas ul li .dropdown-menu a {
		font-size: 12px;
	}
}

.profit_none .none_span  {
	height: 22px;
	    display: inline-block;
}

@media (max-width: 365px) {
	.holiday_works {
		width: 140px;
		order: 1;
	}

	.header-top {
		.topcontacts {
			&.hw {
				.media-body {
					&.phone {
						width: 120px;
						order: 4;

						svg {
							height: 20px;
							margin-right: 10px;
						}
						a {
							min-width: auto;
						}
					}
					&.worktime {
						order: 2;

						span {
							margin-right: 0;
						}
						.mobile_worck {
							span {
								white-space: nowrap;
							}
						}
					}
				}
			}
		}
		.logo_mobile {
			order: 3;
		}
	}
}

@media (max-width: 351px) {
	.product_advantage  {
		p.two_lines2 {
			display: inline-block;
			padding-top: 15px;
			span {
				display: inline-block;
				float: left;
				max-width: 86%;
			}
			span + span {
				margin-top: -15px;
			}
		}
	}
	#buy_form_container {
		.buy_products {
			.table {
				&.shopping-cart-table {
					.column-price {
						width: 100%;
						margin-left: 85px;
					}
				}
			}
		}
	}
}


@media (min-width: 799px) {
	.quick-view .product-info h1.title-product {
		font-size: 24px;
	}
}

@media (max-width: 799px) {

	.quick-view .product-info h1.title-product {
		font-size: 16px;
	}
}