/*.bannercontainer {
	background: transparent url('/image/i/banbg.jpg') no-repeat center top;
}*/

.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}

#button-cart {
	padding: 0 37px;
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

#tab-description {
	img {
		height: auto;
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

#input-topic {
	list-style: none;
	padding: 0;
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.contact-blocks {
	display: flex;
	flex-wrap: wrap;
	tr td {
		padding-bottom: 10px;
	}
	.contact-el {
		width: 50%;
		max-width: 450px;
		display: flex;
		margin-bottom: 20px;
	}
	.contacttext {
		padding-left: 15px;
	}
	@media(max-width: 767px) {
		.contact-el {
			width: 100%;
		}
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $tc;
	top: 0;
	margin: 0 10px;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 170px;
		}
	}
}

.contactsmap {
	display: flex;
	& > div {
		width: 50%;
	}
	@media(max-width: 767px) {
		flex-wrap: wrap;
		& > div {
			width: 100%;
		}
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
		button {
			border: none;
			&:focus, &:active {
				outline: none !important;
			}
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: calc(100% - 75px);
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		border-radius: 0;
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:nth-last-child(-n+1) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 16px;
				margin: 10px 0;
				padding-bottom: 20px;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 700;
				position: absolute;
				bottom: 10px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				padding: 0 25px;
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 421px) {
			top: calc(100% + 2px);
			width: calc(100vw - 30px);
			left: auto;
		}
	}
}

.producttabs {
	margin: 0;
	background: $wh;
	.tab-header {
		font-size: 24px;
		line-height: 32px;
		text-transform: uppercase;
		text-align: center;
		font-weight: 800;
		color: $bk;
		height: 200px;
		background: url('/image/theme_img/ak-rus/bg_tabs.jpg') no-repeat center top;
		background-size: cover;
		span {
			padding: 40px 0;
			float: left;
		}
	}
	.owl-carousel-play {
		margin-top: -83px;
	}
	.container-fluid {
		padding: 0;
	}
	.owl-dots {
		margin-bottom: 15px;
	}
}

#adv_main {
	padding: 60px 0 0 0;
	@include flex();
	justify-content: space-between;
	.advmain {
		flex: 1 1 25%;
		text-align: center;
		padding: 0 15px;
		svg {
			max-width: 110px;
			fill: $tc;
		}
		h4 {
			font-size: 22px;
			font-weight: 300;
			margin: 30px 0;
		}
		p {
			text-align: center;
			color: $bk;
			font-size: 15px;
		}
		.svgcont {
			border: 2px $tc solid;
			display: inline-flex;
			width: 67px;
			height: 67px;
			border-radius: 100%;
			justify-content: center;
			align-items: center;
		}
	}
}

.homebuilder {
	.catgrid {
		background: $lg;
		@include flex();
		justify-content: space-between;
		padding: 15px 30px;
		flex-wrap: wrap;
		&:after, &:before {
			content: none;
		}
		&_element {
			flex: 1;
			border-radius: 5px;
			background: $wh;
			margin: 7px;
			padding: 15px 10px;
			text-align: center;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 500;
			line-height: 1.3;
			min-height: 77px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.pav-inner .catgrid_element {
	img {
		display: none;
	}
}

.owl-dots {
	text-align: center;
	.owl-dot {
		display: inline-block;
		margin: 0 5px;
		svg {
			display: inline-block;
			width: 16px;
			height: 22px;
			stroke: none;
			fill: $bk;
			opacity: .3;
			@include fade();
		}
		&.active {
			svg {
				opacity: 1;
			}
		}
	}
}

.products-owl-carousel .product-block {
	margin-bottom: 0;
}

#checkout-form {
	padding: 0 15px;
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
	padding: 0;
}

.radio_custom {
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		color: $bk;
		font-weight: 500;
		padding-left: 22px;
	}
	a {
		padding-left: 10px;
		font-size: 18px;
		color: $tc;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 16px;
		font-weight: 400;
		text-transform: none;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
			margin: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: inline-block;
			position: relative;
			top: 5px;
			left: 0;
			user-select: none;
			@include size(20px, 20px);
			text-align: center;
			background-color: $wh;
			border: 2px solid $lg2;
			margin-right: 5px;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.65);
			transform-origin: center center;
			width: 100%;
			background: $tc;
			@include opacity(0);
			@include transition(.1s opacity linear);
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
		&.radio input:checked ~ .input-control__indicator {
			border-color: $tc;
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

#direktoru_link {
	text-align: right;
	position: relative;
	margin-bottom: 5px;
	a {
		position: absolute;
		color: $mg;
		left: 0;
		right: 15px;
		text-decoration: underline;
	}
}

.direktoru {
	.radio_custom {
		cursor: default;
		.input-control.radio {
			padding: 0;
		}
		.input-control__indicator {
			top: 4px;
		}
	}
}

.checkbox_custom {
	a {
		text-decoration: underline;
		&.agree b {
			font-weight: 500;
			padding: 0;
			font-size: 12px;
		}
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 25px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 500;
		color: $bk;
		margin: 18px 0;
		text-align: left;
		text-transform: none;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(18px, 18px);
			text-align: center;
			background-color: $white;
			border: 2px $tc solid;
			@include rounded-corners(5px);
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 1px;
			left: 4px;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			@include transform(translateZ(1px) rotate(45deg));
			border-bottom: $tc 2px solid;
			width: 5px;
			height: 10px;
			border-right: $tc 2px solid;
		}
		&.checkbox input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
}

#button-order {
	margin-top: 15px;
}

.catwidget_header h2 {
	text-align: center;
	font-weight: 900;
	font-size: 60px;
	margin-top: 45px;
	margin-bottom: 30px;
}

.bigcat .row {
	margin: 0;
}

.category_widget {
	.img {
		position: relative;
		background: transparent center no-repeat;
		background-size: cover;
		h3 {
			position: absolute;
			text-transform: uppercase;
			text-align: center;
			color: $white;
			display: inline-block;
		}
	}
	.type1 {
		padding-bottom: 50px;
		.img {
			height: 230px;
			margin-bottom: 30px;
			h3 {
				margin: 0;
				height: 60px;
				left: 0;
				right: 0;
				bottom: 0;
				background: transparentize($bk, .3);
				font-weight: 700;
				font-size: 24px;
				padding: 20px 0;
			}
		}
		.descr {
			font-size: 16px;
		}
	}
	.type2 {
		.img {
			background-size: 100%;
			padding-bottom: 30px;
			h3 {
				margin: 0;
				padding: 35px 30px;
				background: transparent;
				font-size: 30px;
				font-weight: 900;
				text-transform: none;
				position: relative;
				text-align: left;
			}
			.descr {
				color: $wh;
				font-weight: 300;
				margin-bottom: 20px;
				padding: 0 30px;
			}
			a {
				@include fade();
				padding: 0 30px;
				margin-bottom: 30px;
				.btn.btn-primary {
					font-weight: 300;
					font-size: 16px;
					padding: 0 32px;
				}
			}
		}
	}
}

.product-compare {
	.comparetable {
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			width: 500px;
		}
	}
	.compare_actions {
		.btn {
			width: 150px;
		}
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 41px;
	height: 41px;
	background-color: $tc;
	z-index: 999;
	@include rounded-corners(100%);
	@include transition(200ms all ease-in-out);
	i {
		font-size: 26px;
		color: $wh;
		position: absolute;
		left: 12px;
		top: 5px;
	}
	&:hover {
		background-color: $bk;
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#input-coupon {
		border-radius: 5px 0 0 5px;
	}
	#button-coupon {
		padding: 0 16px;
		height: 36px;
		line-height: 34px;
		position: relative;
		left: 0;
		z-index: 0;
		border-radius: 0 5px 5px 0;
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid .product-block {
		width: 100%;
		margin: 0;
		overflow: hidden;
	}
}

.product-grid {
	.col-nopadding {
		@include flex();
		flex-wrap: wrap;
		padding: 0;
	}
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.comparetable {
	font-size: 16px;
	overflow-x: auto;
	.price-old {
		color: #999;
		text-decoration: line-through;
		font-size: 15px;
		margin-right: 5px;
	}
}

.owl-prev, .owl-next {
	line-height: 1;
	font-size: 30px;
	position: absolute;
	top: calc(50% - 20px);
	padding: 4px 9px;
	width: 30px;
	height: 40px;
	color: $bk;
	@include transition(0.2s ease all);
	background: transparent;
}

.owl-prev {
	left: -26px;
	background-image: url(/image/i/arrow1l.png);
}

.owl-next {
	right: -26px;
	padding: 4px 11px;
	background-image: url(/image/i/arrow1r.png);
}

.mfilter-box {
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.24), 0 0 5px rgba(0, 0, 0, 0.12);
	.box-heading {
		display: none;
	}
	.box-content {
		border: none;
		.mfilter-heading {
			background: transparent;
			border: none;
			.mfilter-heading-content {
				padding: 20px 0 6px 0;
			}
			.mfilter-head-icon {
				@include size(25px, 20px);
				@include transition(none);
				@include notransform();
				background: url(/image/i/mfminus.png) no-repeat transparent center;
			}
			&.mfilter-collapsed .mfilter-head-icon {
				background-image: url(/image/i/mfplus.png);
			}
		}
		.mfilter-heading-text {
			width: calc(100% - 27px);
			margin: 0 15px;
			> span {
				padding-left: 0;
				font-size: 14px;
				color: $bk;
			}
		}
		.mfilter-col-input {
			padding-right: 8px;
		}
		.mfilter-opts-container {
			border-top: 1px solid $lg;
			padding: 9px 0 5px 0;
			label {
				font-weight: 400;
			}
			.mfilter-counter {
				border-radius: 0;
				background: $wh;
				&:after {
					content: none;
				}
			}
			input[type="text"] {
				border-radius: 0;
			}
			.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
				background: $white;
			}
			.mfilter-slider-slider, #mfilter-price-slider {
				border: $lg 1px solid;
				background: $white;
				margin: 15px 2px 0 5px !important;
				height: 2px !important;
				.ui-slider-range {
					background: $special !important;
					height: 2px;
				}
				.ui-slider-handle {
					background: #ff020b !important;
					height: 12px !important;
					width: 12px !important;
					border-radius: 10px;
					margin-left: -6px !important;
				}
			}
		}
	}
	.mfilter-tb-as-td {
		vertical-align: bottom;
		font-size: 12px;
	}
}

.mfilter-price-slider {
	background: none !important;
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 65px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 16px;
		color: #424242;
		height: 30px;
		font-weight: 400;
		text-transform: lowercase;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		background: url(/image/theme_img/ak-rus/shape_bg.svg) no-repeat center center;
		display: block;
		position: absolute;
		right: 15px;
		top: 0;
		height: 100%;
		width: 13px;
		line-height: 38px;
		color: $black;
		text-align: center;
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	right: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: $tc;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.widget-products {
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
	.owl-next, .owl-prev {
		top: calc(50% - 40px);
	}
}

#request-on-order-form {
	#request-on-order-form fieldset fieldset {
		label:before {
			content: none;
		}
	}
	.btn-buy-product {
		background: $tc !important;
		padding: 0 37px !important;
		color: $white !important;
		@include transition(0.2s all);
		&:hover {
			background: $bk !important;
		}
	}
}

.page-information, .checkout-buy {
	h1 {
		margin-top: 0;
	}
}

.pagescarousel {
	margin: 30px 0;
	.item {
		padding: 0 47px
	}
	h3 {
		margin: 0 0 15px 0;
	}
	.owl-next, .owl-prev {
		top: calc(50% - 20px);
	}
	.owl-prev {
		left: 0;
	}
	.owl-stage {
		@include flex();
		align-items: stretch;
	}
	.owl-item {
		position: relative;
	}
	.pc_wrapper {
		@include flex();
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 50%;
			}
			&.pc_text {
				padding-right: 15px;
				p {
					text-align: justify;
					padding-bottom: 46px;
				}
				.btn {
					position: absolute;
					bottom: 0;
				}
			}
			&.pc_image {
				padding-left: 15px;
			}
		}
	}
}

.holiday_works {
	position: absolute !important;
	left: calc(28% + 44px);
	top: 11px !important;
	text-align: center;
	margin-top: 0;
	border: none !important;
	margin-left: auto !important;
}

.collapse {
	display: none;
	visibility: hidden;

	&.in {
		display: block;
		visibility: visible;
	}
}

tr.collapse.in {
	display: table-row;
}

tbody.collapse.in {
	display: table-row-group;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	@include transition-property(height, visibility);
	@include transition-duration(.35s);
	@include transition-timing-function(ease);
}

table.warranty {
	margin: 15px 0;
	td {
		border: 1px solid $bk;
		padding: 0 10px;
	}
	p {
		margin: 10px 0;
	}
}

#column-left, #column-right {
	border: none;
	display: inline-block;
	width: 100%;
}

.header_filter, #column-left .header_cat {
	font-size: 16px;
	color: $lg;
	background: #4f4f4f;
	padding: 8px 15px;
}

#mfilter-opts-price-min,
#mfilter-opts-price-max,
.mfilter-box .mfilter-opts-slider-min,
.mfilter-box .mfilter-opts-slider-max {
	width: 65px;
	min-width: 65px;
	max-width: 104px;
	display: inline-block;
	padding: 0 5px;
	height: 25px;
	font-family: "Gotham", sans-serif;
	font-style: inherit;
	font-size: 12px;
	border: 1px solid $lg;
	border-radius: 3px;
	color: $bk;
}

#mfilter-opts-price-max, .mfilter-box .mfilter-opts-slider-max {
	float: right;
}

.mfilter-box .box-content .mfilter-heading .mfilter-heading-content {
	padding: 15px 0 6px 0;
}

.mfilter-box .box-content .mfilter-opts-container {
	border-top: none;
	padding: 9px 0 5px 0;
}

#content h1 {
	text-align: left;
	font-weight: 800;
	padding: 0;
}

.mfilter-heading-text svg {
	width: 5px;
	margin-left: 5px;
	fill: #9e9e9e;
	@include transform(rotate(0deg));
}

.mfilter-heading.mfilter-collapsed svg {
	transform: rotate(0);
	@include transform(rotate(90deg));
}

.mfilter-button-bottom {
	padding: 15px 0 20px 0;
}

.mfilter-button-bottom a {
	font-size: 16px;
	font-weight: 400;
}

.mfilter-content > ul > li {
	border-bottom: solid 1px #f5f5f5;
	padding-bottom: 10px;
}

.mfilter-filter-item .mfilter-options {
	padding: 0 15px;
}

.product-list .product-meta .bottom {
	display: none;
}

.product-list .product-block .bottom {
	.action.btn-cart, .btn-oneclick {
		width: 100%;
		margin: 10px 0;
		max-width: 150px;
	}
}

.product-list .product-block .bottom {
	width: 100%;
	flex-direction: inherit;
}

.product-list .listprice {
	.price-old, .price-new {
		width: 100%;
		display: inline-block;
	}
}

#pav-mainnav .header_cat,
.sidebar-offcanvas .header_cat {
	display: none;
}

#column-left .navbar {
	border: none;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.24), 0 0 5px rgba(0, 0, 0, 0.12);
}

#column-left .navbar .container {
	width: 100%;
	padding: 0;
}

#column-left .dropdown-menu {
	min-width: auto;
	width: 100%;
	position: relative;
	margin: 0;
	box-shadow: none;
	padding: 0 15px 10px 15px;
}

#column-left .dropdown-menu img {
	display: none;
}

#column-left .navbar-collapse.collapse {
	padding: 0;
}

#column-left .navbar .open_sub {
	padding: 0;
}

#column-left .navbar .open_sub .dropdown-menu {
	display: block;
	box-shadow: none;
}

#column-left .navbar .navbar-nav > li {
	width: 100%;
}

#column-left .navbar li a {
	font-weight: 500;
	font-size: 16px;
}

#column-left .navbar .navbar-nav li li a {
	font-size: 14px;
	font-weight: normal;
	color: $bk;
}

#column-left .navbar .navbar-nav li li a:hover, #column-left .navbar li li.open_sub2 a, #column-left .navbar .open_sub a {
	color: $special;
}

#column-left .navbar li {
	border-bottom: solid 1px #f5f5f5;
}

#column-left .navbar li li {
	border-bottom: none;
	line-height: 18px;
	margin-bottom: 12px;
}

#column-left .navbar li li:last-child {
	margin-bottom: 0;
}

#column-left .navbar-nav > li > a {
	padding-bottom: 11px;
}

#column-left #menu svg {
	fill: #9e9e9e;
	width: 5px;
	transform: rotate(0);
	@include transform(rotate(90deg));
}

#column-left #menu .dropdown span.pull-right {
	width: auto;
	display: inline-block;
	margin-top: 7px;
	margin-left: 5px;
	float: none !important;
}

body[class*="page-category"] #content {
	padding-top: 0;
}

body[class*="page-category"] #content h1 {
	margin-bottom: 40px;
}

.mfilter-reset-icon {
	display: none;
}

.ui-slider-handle.ui-state-default span {
	font-size: 10px;
	display: inline-block;
	margin-top: 10px;
	color: #222222;
	position: absolute;
	left: 0;
	bottom: -15px;
}

.ui-slider-handle.ui-state-default + .ui-slider-handle.ui-state-default span {
	right: 3px;
	left: auto;
}

.mfilter-content input[type=checkbox], .product-search input[type=checkbox] {
	display: none;
}

.mfilter-content input[type=checkbox] + label:before,
.product-search label.checkbox-inline span {
	content: "\2713";
	color: transparent;
	display: inline-block;
	border: 2px solid #eeeeee;
	background-size: cover;
	font-size: 20px;
	line-height: 22px;
	margin: -2px 5px 0 0;
	height: 18px;
	width: 18px;
	text-align: center;
	vertical-align: middle;
	transition: color ease .3s;
	box-sizing: initial;
	font-weight: bold;
}

.mfilter-content input[type=checkbox]:checked + label:before,
.product-search label.checkbox-inline input[type=checkbox]:checked + span {
	color: $bk;
}

.mfilter-free-container {
	position: fixed;
	max-width: 100%;
	width: 100%;
	padding: 0;
	border: none;
	top: 0;
	z-index: 9999999;
	background: white;
}

#mfilter-free-container-0 {
	overflow-y: scroll !important;
	height: 100% !important;
	-webkit-overflow-scrolling: touch !important;

}

.mfilter-free-button {
	background: #4f4f4f url(/image/theme_img/ak-rus/shape.svg) no-repeat center;
	background-size: auto;
	border: none;
	height: 41px;
	margin: 0;
	right: 0;
	top: 0;
	width: 45px;
	position: absolute;
	z-index: 9999999;
}

.mfilter-free-container {
	margin-left: -310px;
}

.mfilter-box .box-content {
	background: white;
}

.filter_on {
	padding-top: 7px;
	svg {
		fill: $tc;
		height: 20px;
		float: left;
		margin-right: 10px;
		margin-top: 3px;
		display: inline-block;
	}
	span {
		display: inline-block;
		font-size: 16px;
		text-transform: uppercase;
		float: left;
	}
}

.selectric-hide-select.selectric-is-native option {
	font-size: 12px;
	font-style: normal;
	color: $bk;
}

.selectric-hide-select.selectric-is-native option[selected=selected]:checked {
	color: $tc;
	background: none;
}

html.on_filter {
	overflow: hidden;
	margin: 0;
	height: 100%;
}

.search_ctrl #input-search {
	font-style: normal;
}

.search_ctrl #button-search {
	margin-top: 20px;
}

.product-search #content h1 {
	padding: 0 15px 25px 15px;
}

.mfilter-price-slider {
	padding: 0 0 20px 4px !important;
}

.radio-inline, .checkbox-inline {
	font-size: 14px;
	padding: 0;
}

.search_ctrl #input-search {
	border-radius: 0;
}

.search_ctrl .selectric {
	border-color: #e5e5e5;
}

.product-list .product-block .name {
	height: auto !important;
	margin-top: 20px;
}

.compare_button svg {
	width: 20px;
}

.tab-v6 .tab-content > .tab-pane {
	display: none;
	overflow: hidden;
	@include transition(0.2s all);
}

.tab-v6 .tab-content > .active {
	display: block;
	padding: 0 !important;
}

.btn-primary {
	font-size: 16px;
	font-weight: 400;
}

.btn-primary.revers {
	background: $wh;
	border: solid 1px $tc;
	color: $tc;
}

.modal-body {
	padding: 30px 30px 40px 30px;
	h3 {
		font-size: 24px;
		font-weight: 800;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 20px;
	}
	p {
		font-size: 13px;
	}
	.close {
		opacity: 1;
		font-size: inherit;
		padding: 5px 0;

		margin: 0;
		svg {
			fill: $bk;
			width: 15px;
		}
	}
}

.modal.bg {
	background: black;
	display: block !important;
	opacity: 0.5;
	z-index: 0;
}

.modal {
	background: rgba(0, 0, 0, 0.5);
}

.success.product-info {
	width: 832px;
	margin-left: -416px;
	padding: 0;
}

.success {
	.success_bg {
		padding: 30px;
		position: relative;
		display: inline-block;
	}
	.img_product {
		display: inline-block;
		float: left;
		width: 34%;
		min-height: 80px;
		img {
			max-width: 170px;
			width: 100%;
			margin: 0 auto;
			display: block;
		}
	}
	.info {
		display: inline-block;
		float: left;
		width: 66%;
		.price-new {
			padding: 5px 0 20px 0;
			display: inline-block;
		}
		.price-old{
			display: block;
			line-height: 10px;
			margin-top: 20px;
			text-decoration: line-through;
		}
	}
	.add {
		font-weight: 800;
		text-transform: uppercase;
		width: 100%;
		display: inline-block;
		margin-bottom: 10px;
		margin-top: 0;
	}
	.go_cart {
		float: left;
		a {
			font-weight: 300;
			width: 270px;
			text-align: center;
		}
	}
	.go_shop {
		display: inline-block;
		width: 100%;
		margin-top: 20px;
		a {
			border-bottom: solid 1px $lg2;
			color: $lg2;
		}
	}
	.quantity-adder {
		max-width: 170px;
		float: left;
	}
	.quantity-number {
		input {
			width: 140px;
		}
	}
	.close {
		top: 30px;
		right: 30px;
		width: 15px;
		fill: $bk;
	}
	.price-new {
		font-weight: 800;
	}
	#products {
		display: inline-block;
		width: 100%;
		border-top: solid 1px $lg;
		margin-top: 30px;
		padding-bottom: 40px;
		.add {
			margin-top: 30px;
			margin-bottom: 20px;
		}

	}
	#products.product-list .product-block {
		border-top: none;
		border-left: none;
		border-right: none;
		.block-img {
			padding: 0;
			max-width: 100px;
			img {
				padding: 0 15px;
			}
		}
		.title_p {
			width: 370px;
			margin-top: 11px;
			.name {
				font-size: 16px;
			}
		}

		.listprice {
			width: 42%;
			height: 100%;
			padding: 15px 0 0 0;
			.price {
				width: 100%;
			}
			.price-new {
				width: 50%;
				text-align: right;
				padding-right: 30px;
				font-size: 16px;
				margin-top: 11px;
				float: left;
				font-weight: 500;
			}
			button {
				width: 50%;
				max-width: 70px;
				float: left;
				svg {
					width: 25px;
					fill: #fff;
					margin: 11px 0;
				}
			}
		}

	}

}

.success_button {
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.24), 0 0 5px rgba(0, 0, 0, 0.12);
}

input[type=text], input[type=submit], textarea, select {
	-webkit-appearance: none;
}

.success button.btn-cart a, .success button.btn-cart a:hover {
	color: $wh;
	font-size: 28px;
	font-weight: bolder;
}

.tk-comment a, #tab-description a, .description a {
	text-decoration: underline;
}

.btn_opacity_red {
	background: none;
	border: solid 1px $tc;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
	border-radius: 5px;
	color: $tc;
	font-weight: 400;
}

.btn_opacity_red:hover, .btn_opacity_red:active {
	color: $tc;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.24), 0 0 5px rgba(0, 0, 0, 0.12);
}

.scroll {
	overflow-y: auto;
	max-height: 142px;
}

.scroll::-webkit-scrollbar-button {
	background-image: url('');
	background-repeat: no-repeat;
	width: 10px;
	height: 0;
	-webkit-border-radius: 7px;
	border-radius: 7px;
}

.scroll::-webkit-scrollbar-track {
	background: #f5f5f5;
	width: 10px;
	box-shadow: 0 0 3px #6a6a6a inset;
	-webkit-border-radius: 7px;
	border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb {
	-webkit-border-radius: 7px;
	border-radius: 7px;
	background-color: $tc;
	box-shadow: 0 1px 1px $wh inset;
	background-position: center;
	background-repeat: no-repeat;
	width: 10px;
}

.scroll::-webkit-resizer {
	background-image: url('');
	background-repeat: no-repeat;
	width: 10px;
	height: 0;
}

.scroll::-webkit-scrollbar {
	width: 10px;
	padding: 0 3px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
}

#mfilter-price-slider .ui-slider-handle.ui-state-default.none {
	display: none;
}

#news_list {
	@include flex();
	flex-wrap: wrap;
	align-content: flex-start;
	.news_item {
		margin-bottom: 30px;
		.btn-more-link {
			text-decoration-line: underline;
		}
		.border {
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
			border-radius: 5px;
			padding: 0 20px 30px 20px;
		}
		.news_img {
			margin: 0 -20px;
			img {
				border-radius: 5px 5px 0 0;
				width: 100%;
			}
		}
		h3 {
			margin-top: 25px;
			margin-bottom: 20px;
		}
		.news_descr p {
			margin-bottom: 20px;
		}

	}
	.news_item.col-md-4 {
		h3 {
			min-height: 34px;
		}
		.news_descr {
			min-height: 104px;
		}
	}
	.news_item.col-md-6 {
		h3 {
			min-height: 34px;
		}
		img {
			border-radius: 5px;
		}
		.border {
			position: relative;
			padding-bottom: 0;
		}
		.descr_block {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 30px 20px;
			width: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.71) 100%);
			border-radius: 5px;
			.news_descr {
				display: none;
			}
			a {
				color: $wh;
			}
			a:hover, a:focus {
				color: $tc;
			}
		}
	}

}

#news_item {
	padding-bottom: 60px;
	h1 {
		margin-bottom: 30px;
	}
	h2 {
		text-transform: uppercase;
		font-weight: 800;
		margin-top: 30px;
		margin-bottom: 25px;

	}
	h4, .h4 {
		font-size: 16px;
		color: #000;
		margin-top: 30px;
		margin-bottom: 20px;
	}

}

.news_block {
	padding-top: 0;
}

.footer-center .main-footer .box {
	width: 33.33%;
	margin-right: 0;
}

.footer-center .main-footer .box2 {
	padding-right: 30px;
}

.success.send-error {
	background: white;
	border: 1px solid #e4e3e3;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	a {
		text-decoration: underline;
	}
}

.newsletter .checkbox_custom .input-control a {
	color: $wh;
}

#checkout-form .buttons label {
	padding-left: 25px;
}

.qwpage .image.thumbnail {
	display: block;
	padding: 30px;
}

#blackfriday {
	position: fixed;
	bottom: 0;
	width: 100%;
	background: #F44336;
	z-index: 9999;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 25px;
	line-height: 50px; }
#blackfriday span.text {
	padding: 0 50px;
	display: inline-block; }
#blackfriday .fa {
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -12px;
	font-size: 25px;
	cursor: pointer; }

.payment2row {
	display: none;
}
.cheaper-products {

	width: 100%;

	&.general {
		.cheaper-items {
			.cheaper-item {
				&__status {
					font-weight: 500;
				}
				&__price {
					.price-new {
						font-size: 30px;
					}
					.price-old {
						font-size: 14px;
					}
				}
			}
		}

		#button-cart {
			&.status-markdown {
				width: 100%;
			}
		}
	}
	&__title {
		font-weight: 500;
		margin-bottom: 5px;
	}
	.cheaper-items {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;

		.cheaper-item {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 16px;
			width: 100%;
			border: 1px solid rgba(0, 0, 0, 0.12);
			@include rounded-corners(3px);

			.cheaper-item-info {
				display: flex;
				flex-wrap: nowrap;
				justify-content: flex-start;
				flex-direction: column;
				align-items: flex-start;
				gap: 12px;
			}
			&.active {
				border: 1px solid $lg2;
			}
			&__price {
				display: flex;
				align-items: flex-start;
				white-space: nowrap;
				justify-content: flex-end;

				.price-new {
					font-size: 24px;
					font-weight: 500;
					line-height: 1;
				}
				.price-old {
					font-size: 14px;
					@include opacity(0.5);
					text-decoration: line-through;
				}
			}
			&__status {
				color: $dg;
			}

			&__title {
				span {
					color: $lg2;
					font-size: 14px;
					line-height: 17px;
					display: inline-block;
				}
			}

			&:hover {
				cursor: pointer;
				color: $tc !important;
			}
		}
	}
}