.#{$app-brand-prefix}-megamenu {
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		@include flex();
		width: 100%;
		justify-content: space-between;
		> li {
			height: 69px;
			position: relative;
			&.subcat {
				position: static;
			}
			&.parent {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> .dropdown-menu {
				top: 100%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
				> .dropdown-menu-inner {
					> .row {
						margin: 0;
					}
				}
			}
			&:hover, &:focus, &:active {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
				& > a {
					&:after {
						width: 100%;
						left: 0;
						right: 0;
					}
					.caret:before {
						color: $white !important;
					}
				}
			}
			&:first-child {
				&:before {
					content: none;
				}
			}
			> a {
				height: 100%;
				padding: 22px 0;
				font-size: 18px;
				font-weight: 400;
				text-transform: uppercase;
				color: $bk;
				background-color: transparent;
				@include transition(all 200ms ease-out);
				line-height: 22px;
				.caret {
					display: none;
				}
			}
			&.full-width {
				position: static;
			}
			&.specialmenu {
				a {
					color: $special;
				}
			}
		}
		&:before, &:after {
			content: none;
		}
	}
	/* level 2 */
	.dropdown-menu {
		top: 100%;
		left: -9999px;
		height: auto;
		color: $text-color;
		border-top: none;
		min-width: $megamenu-sub-min-width;
		padding: 0;
		background-color: $megamenu-sub-bg-color;
		@include rounded-corners(0);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		.mega-col {
			padding: 0;
		}
		ul.cat_all {
			& > li:hover {
				> a::before {
					display: none;
				}
				& + li > a::before {
					display: none;
				}
			}
			& > li:first-child > a::before {
				display: none;
			}
		}
		ul, ol {
			padding: 0;
		}
		li {
			padding: 0 15px;
			line-height: 20px;
			list-style: none;
			color: $bk;
			background-color: $wh;
			@include transition(0.3s linear 0s);
			&:hover {
				background-color: $tc;
				&, a {
					color: $wh;
				}
				@include rounded-corners(0);
				& > ul.children {
					display: block;
				}
			}
			a {
				padding: 18px 15px 18px 0;
				display: inline-block;
				position: relative;
				width: 100%;
				color: $bk;
				font-size: 16px;
				font-weight: 300;
				background-color: transparent;
				white-space: nowrap;
				&:before {
					content: '';
					background: transparentize($wh, .7);
					display: block;
					position: absolute;
					height: 1px;
					width: 100%;
					top: 0;
				}
			}
			&.haschildren {
				&:after {
					position: absolute;
					content: '';
					background: url(/image/i/ra.png) transparent no-repeat center 0;
					@include size(10px, 17px);
					right: 5px;
					top: 13px;
				}
				&:hover:after {
					background-position: center -17px;
				}
			}
			> ul.children {
				top: 0;
				left: calc(100% + 1px);
				right: -250px;
				bottom: 0;
				position: absolute;
				background: $tc;
				display: none;
				z-index: 99;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				& > li:hover {
					> a::before {
						display: none;
					}
					& + li > a::before {
						display: none;
					}
				}
				& > li:first-child > a::before {
					display: none;
				}
			}
			.click-allcat {
				font-size: 18px;
				position: absolute;
				top: calc(50% - 9px);
				right: 0;
				&:before {
					content: '\f105';
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
			}
			> a {
				.fa {
					color: $bk;
				}
			}
		}
	}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}

	.subcat {
		.dropdown-menu {
			width: calc(100% - 30px);
			margin: 0 15px;
		}
		.catgrid {
			@include flex();
			align-items: center;
			justify-content: flex-start;
			&_element {
				background: $wh;
				padding: 20px;
				align-self: stretch;
				font-size: 16px;
				line-height: 1.1;
				&, a {
					@include fade();
				}
				a {
					text-align: center;
					display: inline-block;
				}
				img {
					max-width: ((($screen-lg-min - 30) / 8) - 50px);
				}
				&:hover {
					background: $tc;
					a {
						color: $wh;
					}
				}
				div.name {
					margin-top: 15px;
				}
			}
		}
	}
}