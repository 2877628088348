.breadcrumb {
	> li {
		display: inline-block;
		font-size: 12px;
		line-height: 20px;
		font-weight: 300;
		color: $bk;
		a {
			color: $bk;
		}
		a:hover {
			color: $dg;
		}
		& > span {
			color: $dg;
		}
	}
	li:after {
		font: normal normal normal 14px/1 $fa;
		content: "\f105";
		padding: 0 0 0 4px;
		color: #c4c4c4;
		font-size: 14px;
	}
	li:last-child:after {
		display: none;
	}
}

